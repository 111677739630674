import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'analysis-shared',
  templateUrl: './analysis-shared.component.html',
  styleUrls: ['./analysis-shared.component.css']
})
export class AnalysisSharedComponent implements OnInit, OnChanges {

  @Input("analysisObject") analysisObject;
  public analysis = null;

  constructor() {

  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.analysisObject.currentValue != null) {
      this.analysisObject.analysis = JSON.parse(this.analysisObject.analysis);
      this.analysis = Object.assign({}, this.analysisObject);
    }
  }



}
