import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { AbstractControl, FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationsService } from "angular2-notifications";
import { EChartsOption } from "echarts";
import * as _ from "lodash";
import { debounce } from "lodash";
import { forkJoin } from "rxjs";
import { first } from "rxjs/operators";
import { AuthService } from "../login/auth.service";
import { SearchService } from "../search/search.service";
import { ShareableService } from "../shareable/shareable.service";
import { MortgageService } from "../shared/mortgage.service";
import { SharedLinkDialogComponent } from "../shared/shared-link-dialog/shared-link-dialog.component";
import { StatisticsService } from "../statistics/statistics.service";
import { AnalysisService } from "./analysis/analysis.service";
import { CalculatorService } from "./calculator.service";

@Component({
  selector: "calculator",
  templateUrl: "./calculator.component.html",
  styleUrls: ["./calculator.component.scss"],
})
export class CalculatorComponent implements OnInit, OnChanges {
  public sectionKeys = null;
  public form = null;
  public sections = null;
  public outputSections = null;
  public editMode: boolean = false;
  public editModeAddress: boolean = false;
  public inputAnalysis: any;

  @Input("analysis")
  public analysis: any;
  public initialized: boolean = false;
  public initializing: boolean = false;

  @Input("enableSharing")
  public enableSharing: any = true;

  @Input("sharedMode")
  public sharedMode: any = false;
  public sharedLinkModalOpen: any = false;

  public mortgagePayment = null;
  public landTransferTax = null;

  public searchCriteria = {
    address: null,
    addressLineTwo: null,
    postal_code: null,
    city: null,
    search_radius: 5,
    houseType: null,
    bedroom_count: null,
    washroom_count: null,
  };

  public chartOption: EChartsOption = {
    xAxis: {
      type: "category",
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: [820, 932, 901, 934, 1290, 1330, 1320],
        type: "pie",
      },
    ],
  };

  public fieldTypes = ["currency", "percent", "number"];

  public name = "";
  public output = null;

  constructor(
    public statisticsService: StatisticsService,
    public authService: AuthService,
    public formBuilder: FormBuilder,
    public calculatorService: CalculatorService,
    public searchService: SearchService,
    public mortgageService: MortgageService,
    public analysisService: AnalysisService,
    public shareableService: ShareableService,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    public notificationService: NotificationsService
  ) {
    //If there is a child, then it's likely initialize
    if (this.route.children[0] != null) {
      this.editMode = true;
    }
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      overallAnalysis: this.formBuilder.group({
        analysisPeriod: [
          "5",
          [Validators.required, Validators.pattern("[0-9].*")],
        ],
        appreciationRate: [
          "5",
          [Validators.required, Validators.pattern("[0-9].*")],
        ],
        propertyTax: ["", [Validators.required]],
        citizen: ["Yes", [Validators.required]],
        firstTimeBuyer: ["No", [Validators.required]],
        realtorCommission: ["5", [Validators.required]],
      }),
      mortgage: this.formBuilder.group({
        purchasePrice: [
          "",
          [Validators.required, Validators.pattern("[0-9].*")],
        ],
        downPaymentPercentage: ["20", [Validators.required]],
        amortizationPeriod: ["30", [Validators.required]],
        mortgageRate: ["", [Validators.required]],
      }),
      monthlyExpenses: this.formBuilder.group({
        vacancyRate: ["5", [Validators.required]],
        maintenanceRate: ["5", [Validators.required]],
        propertyManagementRate: ["10", [Validators.required]],
        propertyInsurance: ["0", []],
        capEx: ["1", []],
        water: ["0"],
        gas: ["0"],
        hydro: ["0"],
      }),
      investment: this.formBuilder.group({
        propertyInspection: ["0", []],
        propertyAppraisal: ["0", []],
        renovations: ["0", []],
        legalFees: ["0", []],
        titleInsurance: ["0", []],
      }),
      monthlyRevenue: this.formBuilder.group({
        rent: ["", [Validators.required]],
        laundry: ["0", []],
        storage: ["0", []],
        parking: ["0", []],
      }),
    });

    this.submit = _.debounce(this.submit, 700);

    this.form.valueChanges.subscribe((value) => {
      this.submit();
    });

    this.sections = {
      overallAnalysis: {
        ordinal: 0,
        sectionTitle: "Overall Analysis",
        formGroup: this.form.get("overallAnalysis"),
        accordianState: true,
        buttonText: "Next",
        fields: [
          {
            label: "Analysis Period (Years)",
            type: "input",
            control: this.form.get("overallAnalysis.analysisPeriod"),
            formControlName: "analysisPeriod",
            unit: "Years 3",
            suffix: "Years 2",
          },
          {
            label: "Appreciation Rate (%)",
            type: "input",
            options: [0, 5, 10, 15, 20],
            control: this.form.get("overallAnalysis.appreciationRate"),
            formControlName: "appreciationRate",
            unit: "%",
            suffix: "%",
          },
          {
            label: "Property Tax ($ /year)",
            type: "input",
            control: this.form.get("overallAnalysis.propertyTax"),
            formControlName: "propertyTax",
            prefix: "$",
          },
          {
            label: "Are you a citizen?",
            type: "dropdown",
            options: ["Yes", "No"],
            control: this.form.get("overallAnalysis.citizen"),
            formControlName: "citizen",
          },
          {
            label: "First-time buyer?",
            type: "dropdown",
            options: ["Yes", "No"],
            control: this.form.get("overallAnalysis.firstTimeBuyer"),
            formControlName: "firstTimeBuyer",
          },
          {
            label: "Realtor Commission (%, Buying & Selling)",
            type: "input",
            control: this.form.get("overallAnalysis.realtorCommission"),
            formControlName: "realtorCommission",
          },
        ],
      },
      mortgage: {
        ordinal: 1,
        sectionTitle: "Mortgage Details",
        formGroup: this.form.get("mortgage"),
        accordianState: false,
        buttonText: "Next",
        fields: [
          {
            label: "Purchase Price ($)",
            type: "input",
            control: this.form.get("mortgage.purchasePrice"),
            formControlName: "purchasePrice",
            placeholder: "200000",
            prefix: "$",
            onChange: this.mortgagePaymentHook.bind(this),
            fieldType: "currency",
          },
          {
            label: "Down Payment (%)",
            type: "input",
            control: this.form.get("mortgage.downPaymentPercentage"),
            formControlName: "downPaymentPercentage",
            unit: "%",
            suffix: "%",
            onChange: this.mortgagePaymentHook.bind(this),
            fieldType: "percent",
          },
          {
            label: "Amortization Period (Years)",
            type: "input",
            control: this.form.get("mortgage.amortizationPeriod"),
            formControlName: "amortizationPeriod",
            suffix: " years",
            onChange: this.mortgagePaymentHook.bind(this),
          },
          {
            label: "Mortgage Rate (%)",
            type: "input",
            control: this.form.get("mortgage.mortgageRate"),
            formControlName: "mortgageRate",
            hint: "",
            suffix: "%",
            onChange: this.mortgagePaymentHook.bind(this),
            fieldType: "percent",
          },
        ],
      },
      monthlyExpenses: {
        ordinal: 2,
        sectionTitle: "Monthly Expenses",
        formGroup: this.form.get("monthlyExpenses"),
        accordianState: false,
        buttonText: "Next",
        fields: [
          {
            label: "Vacancy Rate (% of rent /month)",
            type: "input",
            options: [0, 1, 2, 3, 4, 5],
            control: this.form.get("monthlyExpenses.vacancyRate"),
            formControlName: "vacancyRate",
            unit: "%",
            suffix: "%",
            fieldType: "percent",
          },
          {
            label: "Maintenance (% of rent /month)",
            type: "input",
            options: [0, 1, 2, 3, 4, 5],
            control: this.form.get("monthlyExpenses.maintenanceRate"),
            formControlName: "maintenanceRate",
            unit: "%",
            suffix: "%",
            fieldType: "percent",
          },
          {
            label: "Property Management (% of rent /month)",
            type: "input",
            options: [0, 3, 5, 7, 10],
            control: this.form.get("monthlyExpenses.propertyManagementRate"),
            formControlName: "propertyManagementRate",
            unit: "%",
            suffix: "%",
            fieldType: "percent",
          },
          {
            label: "Property Insurance ($ /month)",
            type: "input",
            control: this.form.get("monthlyExpenses.propertyInsurance"),
            formControlName: "propertyInsurance",
            prefix: "$",
            fieldType: "currency",
          },
          {
            label: "Capital Expenditure Rate (% of Rent /month)",
            type: "dropdown",
            options: [0, 1, 2, 3, 4, 5],
            control: this.form.get("monthlyExpenses.capEx"),
            formControlName: "capEx",
            unit: "%",
            suffix: "%",
            fieldType: "percent",
          },
          {
            label: "Water ($)",
            type: "input",
            control: this.form.get("monthlyExpenses.water"),
            formControlName: "water",
            prefix: "$",
            fieldType: "currency",
          },
          {
            label: "Gas ($)",
            type: "input",
            control: this.form.get("monthlyExpenses.gas"),
            formControlName: "gas",
            prefix: "$",
            fieldType: "currency",
          },
          {
            label: "Hydro/Electricity ($)",
            type: "input",
            control: this.form.get("monthlyExpenses.hydro"),
            formControlName: "hydro",
            prefix: "$",
            fieldType: "currency",
          },
        ],
      },
      investment: {
        ordinal: 3,
        sectionTitle: "Investment",
        formGroup: this.form.get("investment"),
        accordianState: false,
        buttonText: "Next",
        fields: [
          {
            label: "Property Appraisal ($)",
            type: "input",
            control: this.form.get("investment.propertyAppraisal"),
            formControlName: "propertyAppraisal",
            prefix: "$",
            fieldType: "currency",
          },
          {
            label: "Property Inspection ($)",
            type: "input",
            control: this.form.get("investment.propertyInspection"),
            formControlName: "propertyInspection",
            prefix: "$",
            fieldType: "currency",
          },
          {
            label: "Renovations ($)",
            type: "input",
            control: this.form.get("investment.renovations"),
            formControlName: "renovations",
            prefix: "$",
            fieldType: "currency",
          },
          {
            label: "Legal Fees ($)",
            type: "input",
            control: this.form.get("investment.legalFees"),
            formControlName: "legalFees",
            prefix: "$",
            fieldType: "currency",
          },
          {
            label: "Title Insurance ($)",
            type: "input",
            control: this.form.get("investment.titleInsurance"),
            formControlName: "titleInsurance",
            fieldType: "currency",
          },
        ],
      },
      monthlyRevenue: {
        ordinal: 4,
        sectionTitle: "Monthly Revenue",
        formGroup: this.form.get("monthlyRevenue"),
        accordianState: false,
        buttonText: "Finish",
        fields: [
          {
            label: "Rent ($ /month)",
            type: "input",
            control: this.form.get("monthlyRevenue.rent"),
            formControlName: "rent",
            prefix: "$",
            fieldType: "currency",
          },
          {
            label: "Laundry ($ /month)",
            type: "input",
            control: this.form.get("monthlyRevenue.laundry"),
            formControlName: "laundry",
            prefix: "$",
            fieldType: "currency",
          },
          {
            label: "Storage ($ /month)",
            type: "input",
            control: this.form.get("monthlyRevenue.storage"),
            formControlName: "storage",
            prefix: "$",
            fieldType: "currency",
          },
          {
            label: "Parking ($ /month)",
            type: "input",
            control: this.form.get("monthlyRevenue.parking"),
            formControlName: "parking",
            prefix: "$",
            fieldType: "currency",
          },
        ],
      },
    };

    this.outputSections = {
      overall: {
        sectionTitle: "Overall Analysis",
        fields: [
          {
            label: "Analysis Period (Years)",
            type: "input",
            control: this.form.get("overallAnalysis.analysisPeriod"),
            formControlName: "analysisPeriod",
            currentUnitType: "number",
            transformer: this.baseTransformer(
              this.form.get("overallAnalysis.analysisPeriod")
            ),
            editable: true,
            supportedTypes: ["number"],
          },
          {
            label: "Appreciation Rate ( /year)",
            type: "input",
            options: [0, 5, 10, 15, 20],
            control: this.form.get("overallAnalysis.appreciationRate"),
            formControlName: "appreciationRate",
            currentUnitType: "percent",
            transformer: this.baseTransformer(
              this.form.get("overallAnalysis.appreciationRate")
            ),
            editable: true,
            supportedTypes: ["percent"],
          },
          {
            label: "Property Tax ( /year)",
            type: "input",
            control: this.form.get("overallAnalysis.propertyTax"),
            formControlName: "propertyTax",
            currentUnitType: "currency",
            transformer: this.baseTransformer(
              this.form.get("overallAnalysis.propertyTax")
            ),
            editable: true,
            supportedTypes: ["currency"],
          },
          {
            label: "Citizen",
            type: "dropdown",
            options: ["Yes", "No"],
            control: this.form.get("overallAnalysis.citizen"),
            formControlName: "citizen",
            currentUnitType: "string",
            // transformer: this.baseTransformer(
            //   this.form.get("overallAnalysis.citizen")
            // ),
            editable: true,
            // supportedTypes: ["currency"],
          },
          {
            label: "First-Time Buyer",
            type: "dropdown",
            options: ["Yes", "No"],
            control: this.form.get("overallAnalysis.firstTimeBuyer"),
            formControlName: "firstTimeBuyer",
            currentUnitType: "string",
            // transformer: this.baseTransformer(
            //   this.form.get("overallAnalysis.firstTimeBuyer")
            // ),
            editable: true,
            // supportedTypes: ["currency"],
          },
          {
            label: "Realtor Commission (%, Buying & Selling)",
            type: "input",
            control: this.form.get("overallAnalysis.realtorCommission"),
            formControlName: "realtorCommission",
            currentUnitType: "percent",
            transformer: this.baseTransformer(
              this.form.get("overallAnalysis.realtorCommission")
            ),
            editable: true,
            supportedTypes: ["percent"],
          },
        ],
      },
      mortgage: {
        sectionTitle: "Mortgage",
        fields: [
          {
            label: "Purchase Price",
            type: "input",
            control: this.form.get("mortgage.purchasePrice"),
            formControlName: "purchasePrice",
            onChange: this.mortgagePaymentHook.bind(this),
            currentUnitType: "currency",
            transformer: this.baseTransformer(
              this.form.get("mortgage.purchasePrice")
            ),
            editable: true,
            supportedTypes: ["currency"],
          },
          {
            label: "Down Payment",
            type: "input",
            control: this.form.get("mortgage.downPaymentPercentage"),
            formControlName: "downPaymentPercentage",
            transformer: this.baseTransformer(
              this.form.get("mortgage.downPaymentPercentage")
            ),
            editable: true,
            onChange: this.mortgagePaymentHook.bind(this),
            currentUnitType: "percent",
            supportedTypes: ["percent"],
          },
          {
            label: "Amortization Period",
            type: "input",
            control: this.form.get("mortgage.amortizationPeriod"),
            formControlName: "amortizationPeriod",
            suffix: " years",
            onChange: this.mortgagePaymentHook.bind(this),
            transformer: this.baseTransformer(
              this.form.get("mortgage.amortizationPeriod")
            ),
            editable: true,
            currentUnitType: "number",
            supportedTypes: ["number"],
          },
          {
            label: "Mortgage Rate (%)",
            type: "input",
            control: this.form.get("mortgage.mortgageRate"),
            formControlName: "mortgageRate",
            onChange: this.mortgagePaymentHook.bind(this),
            transformer: this.baseTransformer(
              this.form.get("mortgage.mortgageRate")
            ),
            editable: true,
            currentUnitType: "percent",
            supportedTypes: ["percent"],
          },
        ],
        outputs: [
          {
            name: "Mortgage Payment",
            value: (value) => {
              return this.mortgagePayment;
            },
            valueKey: "mortgage",
            fieldType: "currency",
          },
        ],
      },
      monthlyExpenses: {
        sectionTitle: "Monthly Expenses",
        fields: [
          {
            label: "Vacancy Rate (% of rent /month)",
            type: "input",
            control: this.form.get("monthlyExpenses.vacancyRate"),
            formControlName: "vacancyRate",
            currentUnitType: "percent",
            transformer: this.relativeTransformer(
              this.form.get("monthlyExpenses.vacancyRate"),
              this.form.get("monthlyRevenue.rent"),
              "monthlyExpenses.fields[0].currentUnitType"
            ),
            converter: this.relativeConverter(
              this.form.get("monthlyExpenses.vacancyRate"),
              this.form.get("monthlyRevenue.rent")
            ),
            editable: true,
            supportedTypes: ["percent", "currency"],
          },
          {
            label: "Maintenance (% of rent /month)",
            type: "input",
            options: [0, 1, 2, 3, 4, 5],
            control: this.form.get("monthlyExpenses.maintenanceRate"),
            formControlName: "maintenanceRate",
            currentUnitType: "percent",
            transformer: this.relativeTransformer(
              this.form.get("monthlyExpenses.maintenanceRate"),
              this.form.get("monthlyRevenue.rent"),
              "monthlyExpenses.fields[1].currentUnitType"
            ),
            converter: this.relativeConverter(
              this.form.get("monthlyExpenses.maintenanceRate"),
              this.form.get("monthlyRevenue.rent")
            ),
            editable: true,
            supportedTypes: ["percent", "currency"],
          },
          {
            label: "Property Management (% of rent /month)",
            type: "input",
            options: [0, 3, 5, 7, 10],
            control: this.form.get("monthlyExpenses.propertyManagementRate"),
            formControlName: "propertyManagementRate",
            currentUnitType: "percent",
            transformer: this.relativeTransformer(
              this.form.get("monthlyExpenses.propertyManagementRate"),
              this.form.get("monthlyRevenue.rent"),
              "monthlyExpenses.fields[2].currentUnitType"
            ),
            converter: this.relativeConverter(
              this.form.get("monthlyExpenses.propertyManagementRate"),
              this.form.get("monthlyRevenue.rent")
            ),
            editable: true,
            supportedTypes: ["percent", "currency"],
          },
          {
            label: "Property Insurance ($ /month)",
            type: "input",
            control: this.form.get("monthlyExpenses.propertyInsurance"),
            formControlName: "propertyInsurance",
            currentUnitType: "currency",
            editable: true,
            transformer: this.baseTransformer(
              this.form.get("monthlyExpenses.propertyInsurance")
            ),
            supportedTypes: ["currency"],
          },
          {
            label: "Capital Expenditure Rate (% of rent /month)",
            type: "input",
            options: [0, 1, 2, 3, 4, 5],
            control: this.form.get("monthlyExpenses.capEx"),
            formControlName: "capEx",
            currentUnitType: "percent",
            editable: true,
            transformer: this.relativeTransformer(
              this.form.get("monthlyExpenses.capEx"),
              this.form.get("monthlyRevenue.rent"),
              "monthlyExpenses.fields[4].currentUnitType"
            ),
            converter: this.relativeConverter(
              this.form.get("monthlyExpenses.capEx"),
              this.form.get("monthlyRevenue.rent")
            ),
            supportedTypes: ["percent", "currency"],
          },
          {
            label: "Water ($ /month)",
            type: "input",
            control: this.form.get("monthlyExpenses.water"),
            formControlName: "water",
            currentUnitType: "currency",
            editable: true,
            transformer: this.baseTransformer(
              this.form.get("monthlyExpenses.water")
            ),
            supportedTypes: ["currency"],
          },
          {
            label: "Gas ($ /month)",
            type: "input",
            control: this.form.get("monthlyExpenses.gas"),
            formControlName: "gas",
            currentUnitType: "currency",
            editable: true,
            transformer: this.baseTransformer(
              this.form.get("monthlyExpenses.gas")
            ),
            supportedTypes: ["currency"],
          },
          {
            label: "Hydro/Electricity ($ /month)",
            type: "input",
            control: this.form.get("monthlyExpenses.hydro"),
            formControlName: "hydro",
            currentUnitType: "currency",
            editable: true,
            transformer: this.baseTransformer(
              this.form.get("monthlyExpenses.hydro")
            ),
            supportedTypes: ["currency"],
          },
          {
            label: "Property Tax",
            type: "input",
            control: this.form.get("overallAnalysis.propertyTax"),
            formControlName: "propertyTax",
            currentUnitType: "currency",
            transformer: () => {
              return (
                this.baseTransformer(
                  this.form.get("overallAnalysis.propertyTax")
                )() / 12
              );
            },
            supportedTypes: ["currency"],
          },
        ],
        outputs: [
          {
            name: "Total Monthly Expenses",
            value: this.getTotalForSection.bind(this),
            valueKey: "monthlyExpenses",
            fieldType: "currency",
          },
        ],
      },
      monthlyRevenue: {
        sectionTitle: "Monthly Revenue",
        fields: [
          {
            label: "Rent",
            type: "input",
            control: this.form.get("monthlyRevenue.rent"),
            formControlName: "rent",
            currentUnitType: "currency",
            editable: true,
            transformer: this.baseTransformer(
              this.form.get("monthlyRevenue.rent")
            ),
            supportedTypes: ["currency"],
          },
          {
            label: "Laundry",
            type: "input",
            control: this.form.get("monthlyRevenue.laundry"),
            formControlName: "laundry",
            currentUnitType: "currency",
            editable: true,
            transformer: this.baseTransformer(
              this.form.get("monthlyRevenue.laundry")
            ),
            supportedTypes: ["currency"],
          },
          {
            label: "Storage",
            type: "input",
            control: this.form.get("monthlyRevenue.storage"),
            formControlName: "storage",
            editable: true,
            currentUnitType: "currency",
            transformer: this.baseTransformer(
              this.form.get("monthlyRevenue.storage")
            ),
            supportedTypes: ["currency"],
          },
          {
            label: "Parking",
            type: "input",
            control: this.form.get("monthlyRevenue.parking"),
            formControlName: "parking",
            editable: true,
            currentUnitType: "currency",
            transformer: this.baseTransformer(
              this.form.get("monthlyRevenue.parking")
            ),
            supportedTypes: ["currency"],
          },
        ],
        outputs: [
          {
            name: "Total Monthly Revenue",
            value: this.getTotalForSection.bind(this),
            valueKey: "monthlyRevenue",
            fieldType: "currency",
          },
        ],
      },
      investment: {
        sectionTitle: "Investments",
        fields: [
          {
            label: "Property Appraisal",
            type: "input",
            control: this.form.get("investment.propertyAppraisal"),
            formControlName: "propertyAppraisal",
            editable: true,
            currentUnitType: "currency",
            transformer: this.baseTransformer(
              this.form.get("investment.propertyAppraisal")
            ),
            supportedTypes: ["currency"],
          },
          {
            label: "Property Inspection",
            type: "input",
            control: this.form.get("investment.propertyInspection"),
            formControlName: "propertyInspection",
            editable: true,
            currentUnitType: "currency",
            transformer: this.baseTransformer(
              this.form.get("investment.propertyInspection")
            ),
            supportedTypes: ["currency"],
          },
          {
            label: "Renovations",
            type: "input",
            control: this.form.get("investment.renovations"),
            formControlName: "renovations",
            editable: true,
            currentUnitType: "currency",
            transformer: this.baseTransformer(
              this.form.get("investment.renovations")
            ),
            supportedTypes: ["currency"],
          },
          {
            label: "Legal Fees",
            type: "input",
            control: this.form.get("investment.legalFees"),
            formControlName: "legalFees",
            editable: true,
            currentUnitType: "currency",
            transformer: this.baseTransformer(
              this.form.get("investment.legalFees")
            ),
            supportedTypes: ["currency"],
          },
          {
            label: "Title Insurance",
            type: "input",
            control: this.form.get("investment.titleInsurance"),
            formControlName: "titleInsurance",
            fieldType: "currency",
            editable: true,
            currentUnitType: "currency",
            transformer: this.baseTransformer(
              this.form.get("investment.titleInsurance")
            ),
            supportedTypes: ["currency"],
          },
          {
            label: "Down Payment",
            type: "input",
            control: this.form.get("mortgage.purchasePrice"),
            formControlName: "purchasePrice",
            editable: false,
            currentUnitType: "currency",
            transformer: () => {
              return (
                (this.form.get("mortgage").get("purchasePrice").value *
                  this.form.get("mortgage").get("downPaymentPercentage")
                    .value) /
                100
              );
            },
            supportedTypes: ["currency"],
          },
          {
            label: "Land Transfer Tax",
            type: "display",
            currentUnitType: "currency",
            value: this.getLandTransferTax.bind(this),
            transformer: () => {
              return this.landTransferTax;
            },
            supportedTypes: ["currency"],
          },
        ],
        outputs: [
          // {
          //   name: "Land Transfer Tax",
          //   value: (value) => {
          //     return this.landTransferTax;
          //   },
          //   valueKey: "landTransferTax",
          //   fieldType: "currency",
          // },
          {
            name: "Total Investment",
            value: this.getTotalForSection.bind(this),
            valueKey: "investment",
            fieldType: "currency",
          },
        ],
      },
    };

    this.sectionKeys = Object.keys(this.sections);
    // this.name = this.authService.getAuthState().landlord.firstName;
    if (this.sharedMode) {
      window["mixpanel"].track("Shared analysis viewed");
      this.onInit();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.analysis = changes.analysis.currentValue;
    if (
      this.form != null &&
      !this.initialized &&
      changes.analysis.currentValue != null
    ) {
      // this.analysis = changes.analysis.currentValue;
      if (!this.editMode) {
        this.onInit();
      } else {
        this.mortgageHook();
      }
    }
  }

  public onInit() {
    this.initializeFromInput();
    this.mortgageHook(() => {
      this.mortgagePaymentHook();
      let amounts = this.initializeMortgagePayment();
      if (amounts != null) {
        return this.calculatorService
          .mortgagePayment(
            amounts[0],
            amounts[1],
            amounts[2],
            amounts[3],
            amounts[4]
          )
          .subscribe((payment) => {
            this.mortgagePayment = payment;
            this.initialized = true;
            this.submit();
          });
      }
    });
  }

  public initializeFromInput() {
    this.initializing = true;
    let analysisDetails = this.analysis.analysis;

    if (analysisDetails.propertyDetails != null) {
      this.searchCriteria.address = analysisDetails?.propertyDetails?.address;
      this.searchCriteria.addressLineTwo =
        analysisDetails?.propertyDetails?.addressLineTwo;
      this.searchCriteria.city = analysisDetails?.propertyDetails?.city;
      this.searchCriteria.postal_code =
        analysisDetails?.propertyDetails?.postalCode;
      this.searchCriteria.houseType =
        analysisDetails?.propertyDetails?.houseType;
      this.searchCriteria.bedroom_count =
        analysisDetails?.propertyDetails?.bedrooms;
      this.searchCriteria.washroom_count =
        analysisDetails?.propertyDetails?.washrooms;
    }

    this.form
      .get("monthlyRevenue")
      .get("rent")
      .patchValue(String(analysisDetails.expectedIncomeJson?.rentalIncome));
    this.form
      .get("monthlyRevenue")
      .get("laundry")
      .patchValue(
        String(analysisDetails.expectedIncomeJson?.otherIncomeSources.laundry)
      );
    this.form
      .get("monthlyRevenue")
      .get("storage")
      .patchValue(
        String(analysisDetails.expectedIncomeJson?.otherIncomeSources.storage)
      );
    this.form
      .get("monthlyRevenue")
      .get("parking")
      .patchValue(
        String(analysisDetails.expectedIncomeJson?.otherIncomeSources.parking)
      );

    this.form
      .get("overallAnalysis")
      .get("analysisPeriod")
      .patchValue(String(analysisDetails?.analysisTimePeriod));
    this.form
      .get("overallAnalysis")
      .get("appreciationRate")
      .patchValue(String(analysisDetails?.estimatedAppreciationRate * 100));
    this.form
      .get("overallAnalysis")
      .get("propertyTax")
      .patchValue(
        String(analysisDetails?.expectedMonthlyExpensesJson?.taxes * 12)
      );
    let citizen =
      analysisDetails?.landTransferRequest?.citizen == true
        ? String("Yes")
        : String("No");
    this.form.get("overallAnalysis").get("citizen").patchValue(citizen);
    let firstTimeBuyer =
      analysisDetails?.landTransferRequest?.firstTimeBuyer == true
        ? "Yes"
        : "No";
    this.form
      .get("overallAnalysis")
      .get("firstTimeBuyer")
      .patchValue(firstTimeBuyer);
    this.form
      .get("overallAnalysis")
      .get("realtorCommission")
      .patchValue(String(analysisDetails?.realtorCommission * 100));

    this.form
      .get("mortgage")
      .get("purchasePrice")
      .patchValue(String(analysisDetails?.propertyDetails?.propertyPrice));
    this.form
      .get("mortgage")
      .get("mortgageRate")
      .patchValue(String(analysisDetails?.mortgageDetails?.annualMortgageRate));
    this.form
      .get("mortgage")
      .get("amortizationPeriod")
      .patchValue(
        String(analysisDetails?.mortgageDetails?.mortgageTermInMonths / 12)
      );
    this.form
      .get("mortgage")
      .get("downPaymentPercentage")
      .patchValue(
        String(
          (analysisDetails?.mortgageDetails?.downPayment /
            analysisDetails?.propertyDetails?.propertyPrice) *
            100
        )
      );

    this.form
      .get("monthlyExpenses")
      .get("vacancyRate")
      .patchValue(
        String(
          (
            (analysisDetails?.expectedMonthlyExpensesJson?.vacancy /
              analysisDetails?.expectedIncomeJson?.rentalIncome) *
            100
          ).toFixed(0)
        )
      );
    this.form
      .get("monthlyExpenses")
      .get("maintenanceRate")
      .patchValue(
        String(
          (
            (analysisDetails?.expectedMonthlyExpensesJson?.maintenance /
              analysisDetails?.expectedIncomeJson?.rentalIncome) *
            100
          ).toFixed(0)
        )
      );
    this.form
      .get("monthlyExpenses")
      .get("propertyManagementRate")
      .patchValue(
        String(
          (
            (analysisDetails?.expectedMonthlyExpensesJson?.propertyManagement /
              analysisDetails?.expectedIncomeJson?.rentalIncome) *
            100
          ).toFixed(0)
        )
      );
    this.form
      .get("monthlyExpenses")
      .get("capEx")
      .patchValue(
        String(
          (
            (analysisDetails?.expectedMonthlyExpensesJson?.capEx /
              analysisDetails?.expectedIncomeJson?.rentalIncome) *
            100
          ).toFixed(0)
        )
      );
    this.form
      .get("monthlyExpenses")
      .get("water")
      .patchValue(String(analysisDetails?.expectedMonthlyExpensesJson?.water));
    this.form
      .get("monthlyExpenses")
      .get("gas")
      .patchValue(String(analysisDetails?.expectedMonthlyExpensesJson?.gas));
    this.form
      .get("monthlyExpenses")
      .get("hydro")
      .patchValue(
        String(analysisDetails?.expectedMonthlyExpensesJson?.electricity)
      );
    this.form
      .get("monthlyExpenses")
      .get("propertyInsurance")
      .patchValue(
        String(analysisDetails?.expectedMonthlyExpensesJson?.propertyInsurance)
      );

    this.form
      .get("investment")
      .get("propertyInspection")
      .patchValue(String(analysisDetails?.oneTimeExpenses?.homeInspectionCost));
    this.form
      .get("investment")
      .get("propertyAppraisal")
      .patchValue(String(analysisDetails?.oneTimeExpenses?.propertyAppraisal));
    this.form
      .get("investment")
      .get("renovations")
      .patchValue(String(analysisDetails?.oneTimeExpenses?.renovations));
    this.form
      .get("investment")
      .get("legalFees")
      .patchValue(String(analysisDetails?.oneTimeExpenses?.legalCosts));
    this.form
      .get("investment")
      .get("titleInsurance")
      .patchValue(String(analysisDetails?.oneTimeExpenses?.titleInsurance));

    this.initializing = false;
  }

  public submit() {
    if (this.initializing) {
      return;
    }

    if (!this.customValidation()) {
      return;
    }

    this.createAnalysisObject();
    this.analysisService
      .saveAnalysis(this.analysis.id, this.analysis)
      .subscribe((analysis: any) => {
        window["mixpanel"].track("Updated analysis value");
        analysis.analysis = JSON.parse(analysis.analysis);
        this.analysis = analysis;
      });

    if (this.form.valid) {
      this.calculatorService
        .generateReport(this.analysis.analysis)
        .subscribe((finalAnalysis) => {
          this.output = finalAnalysis;
          this.initializeFromReport(this.output);
        });
    }

    if (this.analysis.analysis.propertyDetails.address == null) {
      this.editModeAddress = true;
    }
  }

  public baseTransformer(control) {
    return () => {
      return parseFloat(control.value);
    };
  }

  public relativeConverter(control, relativeControl) {
    return (newUnitType) => {
      let newValue = 0;
      if (newUnitType == "percent") {
        newValue = (control.value / relativeControl.value) * 100;
      } else {
        newValue = (control.value / 100) * relativeControl.value;
      }
      return newValue;
    };
  }

  public relativeTransformer(control, relativeControl, currentUnitTypeKey) {
    if (!relativeControl) {
      throw new Error(
        "Any field that uses the relativeTransformer must have a relative control specified"
      );
    }
    return () => {
      let currentUnitType = _.get(this.outputSections, currentUnitTypeKey);
      let value = 0;
      if (currentUnitType == "percent") {
        value =
          (parseFloat(control.value) / 100) * parseFloat(relativeControl.value);
      } else {
        value = parseFloat(control.value);
      }
      return value;
    };
  }

  public createAnalysisObject() {
    let downPaymentPercentage = this.form
      .get("mortgage")
      .get("downPaymentPercentage").value;
    let downPayment =
      this.form.get("mortgage").get("purchasePrice").value *
      (downPaymentPercentage / 100);
    let rent = parseFloat(this.form.get("monthlyRevenue").get("rent").value);

    let monthlyTaxes =
      parseInt(this.form.get("overallAnalysis").get("propertyTax").value) / 12;

    let analysis = {
      propertyDetails: {
        address: this.searchCriteria.address,
        addressLineTwo: "",
        city: this.searchCriteria.city,
        postalCode: this.searchCriteria.postal_code,
        houseType: this.searchCriteria.houseType,
        bedrooms: this.searchCriteria.bedroom_count,
        washrooms: this.searchCriteria.washroom_count,
        propertyPrice: parseFloat(
          this.form.get("mortgage").get("purchasePrice").value
        ),
      },
      expectedMonthlyExpensesJson: {
        gas: parseFloat(this.form.get("monthlyExpenses").get("gas").value),
        water: parseFloat(this.form.get("monthlyExpenses").get("water").value),
        electricity: parseFloat(
          this.form.get("monthlyExpenses").get("hydro").value
        ),
        capEx: _.find(this.outputSections["monthlyExpenses"].fields, {
          label: "Capital Expenditure Rate (% of rent /month)",
        }).converter("currency"),
        taxes: monthlyTaxes,
        vacancy: _.find(this.outputSections["monthlyExpenses"].fields, {
          label: "Vacancy Rate (% of rent /month)",
        }).converter("currency"),
        maintenance: _.find(this.outputSections["monthlyExpenses"].fields, {
          label: "Maintenance (% of rent /month)",
        }).converter("currency"),
        propertyInsurance: parseFloat(
          this.form.get("monthlyExpenses").get("propertyInsurance").value
        ),
        propertyManagement: _.find(
          this.outputSections["monthlyExpenses"].fields,
          { label: "Property Management (% of rent /month)" }
        ).converter("currency"),
        otherExpenses: {},
      },
      oneTimeExpenses: {
        homeInspectionCost: parseFloat(
          this.form.get("investment").get("propertyInspection").value
        ),
        propertyAppraisal: parseFloat(
          this.form.get("investment").get("propertyAppraisal").value
        ),
        legalCosts: parseFloat(
          this.form.get("investment").get("legalFees").value
        ),
        titleInsurance: parseFloat(
          this.form.get("investment").get("titleInsurance").value
        ),
        renovations: parseFloat(
          this.form.get("investment").get("renovations").value
        ),
      },
      mortgageDetails: {
        downPayment: downPayment,
        mortgagePayment: this.mortgagePayment,
        annualMortgageRate: parseFloat(
          this.form.get("mortgage").get("mortgageRate").value
        ),
        mortgageTermInMonths:
          this.form.get("mortgage").get("amortizationPeriod").value * 12,
      },
      expectedIncomeJson: {
        rentalIncome: rent,
        otherIncomeSources: {
          laundry: parseFloat(this.form.get("monthlyRevenue.laundry").value),
          storage: parseFloat(this.form.get("monthlyRevenue.storage").value),
          parking: parseFloat(this.form.get("monthlyRevenue.parking").value),
        },
      },
      estimatedAppreciationRate:
        this.form.get("overallAnalysis").get("appreciationRate").value / 100,
      realtorCommission:
        this.form.get("overallAnalysis").get("realtorCommission").value / 100,
      analysisTimePeriod: parseInt(
        this.form.get("overallAnalysis").get("analysisPeriod").value
      ),
      landTransferRequest: {
        firstTimeBuyer:
          this.form.get("overallAnalysis").get("firstTimeBuyer").value == "Yes"
            ? true
            : false,
        citizen:
          this.form.get("overallAnalysis").get("citizen").value == "Yes"
            ? true
            : false,
      },
    };

    this.inputAnalysis = analysis;
    this.analysis.analysis = analysis;
  }

  public updatePropertyDetails($event) {
    let analysis = Object.assign({}, this.analysis);
    analysis.analysis.propertyDetails["address"] = $event.address;
    analysis.analysis.propertyDetails["addressLineTwo"] = $event.addressLineTwo;
    analysis.analysis.propertyDetails["city"] = $event.city;
    analysis.analysis.propertyDetails["postalCode"] = $event.postal_code;
    analysis.analysis.propertyDetails["houseType"] = $event.houseType;
    analysis.analysis.propertyDetails["bedrooms"] = $event.bedroom_count;
    analysis.analysis.propertyDetails["washrooms"] = $event.washroom_count;

    this.analysisService
      .saveAnalysis(this.analysis.id, analysis)
      .subscribe((analysis: any) => {
        analysis.analysis = JSON.parse(analysis.analysis);
        this.analysis = analysis;
        this.submit();
      });
  }

  public gotoNextPanel(sectionKey) {
    this.sections[sectionKey].accordianState = false;
    let currentOrdinal = this.sections[sectionKey].ordinal;
    let nextSection = _.find(this.sections, { ordinal: ++currentOrdinal });
    if (nextSection != null) {
      nextSection.accordianState = true;
    }
  }

  public rentHook(event) {
    if (this.searchCriteria.postal_code != null) {
      console.log("Rent hook");
      console.log(event);
      this.updatePropertyDetails(event);
      this.searchService
        .analyze(this.searchCriteria)
        .subscribe((results: any) => {
          if (results.mean != null) {
            this.form
              .get("monthlyRevenue.rent")
              .patchValue(parseFloat(results.mean).toFixed(0));
          }
        });
    }
  }

  public initializeMortgageRate(rates) {
    let mortgageRateField = _.find(this.sections["mortgage"].fields, {
      label: "Mortgage Rate (%)",
    });
    mortgageRateField.hint =
      "Current best interest rate is provided by " +
      rates[0].provider +
      " for the five year fixed rate @ " +
      rates[0].fiveYearFixed +
      " %";
    if (
      this.form.get("mortgage.mortgageRate").value == null ||
      this.form.get("mortgage.mortgageRate").value <= 0
    ) {
      this.form
        .get("mortgage.mortgageRate")
        .patchValue(String(rates[0].fiveYearFixed));
    }
  }

  public mortgageHook(callback?) {
    this.mortgageService.mortgageRates().subscribe((rates) => {
      this.initializeMortgageRate(rates);
      if (callback) {
        callback();
      }
    });
  }

  public initializeMortgagePayment() {
    if (this.form.get("mortgage").valid) {
      let downPaymentPercentage = this.form
        .get("mortgage")
        .get("downPaymentPercentage").value;
      let downPayment =
        this.form.get("mortgage").get("purchasePrice").value *
        (downPaymentPercentage / 100);
      let mortgageAmount =
        this.form.get("mortgage").get("purchasePrice").value - downPayment;
      let annualMortgageRate = this.form
        .get("mortgage")
        .get("mortgageRate").value;
      let mortgageTermInMonths =
        this.form.get("mortgage").get("amortizationPeriod").value * 12;
      // let rent = parseFloat(this.form.get("monthlyRevenue").get("rent").value);
      return [
        mortgageAmount,
        annualMortgageRate,
        mortgageTermInMonths,
        parseFloat(this.form.get("mortgage").get("purchasePrice").value),
        downPayment,
      ];
    } else {
      return null;
    }
  }

  public mortgagePaymentHook() {
    let amounts = this.initializeMortgagePayment();
    if (amounts != null) {
      this.calculatorService
        .mortgagePayment(
          amounts[0],
          amounts[1],
          amounts[2],
          amounts[3],
          amounts[4]
        )
        .subscribe((payment) => {
          this.mortgagePayment = payment;
        });
    }
  }

  public calculate() {
    if (this.searchCriteria.postal_code != null) {
      this.router
        .navigate(["/analysis", this.analysis.id])
        .then(() => {
          window.location.reload();
        });
    }
    else {
      this.notificationService.error("Address Incomplete", "Can you please make sure there is an address within the field.")
    }
  }

  public getTotalForSection(sectionKey) {
    let fields: any = this.outputSections[sectionKey].fields;
    let expenses = 0;
    for (let field of fields) {
      expenses += field.transformer();
    }
    return expenses;
  }

  public getShareableLink() {
    if (!this.sharedLinkModalOpen) {
      this.sharedLinkModalOpen = true;
      this.analysisService
        .createShareableLink(this.analysis.id)
        .subscribe((link: any) => {
          const dialogRef = this.dialog.open(SharedLinkDialogComponent, {
            width: "400px",
            height: "200px",
            data: {
              link: location.origin + "/shared/analysis/" + link["urlCode"],
            },
          });

          this.dialog.afterAllClosed.subscribe(() => {
            this.sharedLinkModalOpen = false;
          });
        });
    }
  }

  public initializeFromReport(analysis) {
    this.landTransferTax = analysis.landTransferTaxCalculationJson.netAmount;
  }

  public getLandTransferTax() {
    return this.landTransferTax;
  }

  public customValidation() {
    //TODO: Later on - after we merge the input and output sections, we shoudl include these validation as part of thsoe objects. Better yet create different classes for each field, and have everythign within here.
    let isValid: boolean = true;
    isValid = isValid && this.chmcAmortizationPeriodValidation();
    return isValid;
  }

  public chmcAmortizationPeriodValidation(): boolean {
    let amortizationPeriod = this.form
      .get("mortgage")
      .get("amortizationPeriod").value;
    let downPaymentPercentage = this.form
      .get("mortgage")
      .get("downPaymentPercentage").value;
    if (downPaymentPercentage < 20 && amortizationPeriod > 25) {
      this.notificationService.error(
        "If your down payment percentage is below 20%, your amortization period must at or below 25 years."
      );
      return false;
    }
    return true;
  }
}
