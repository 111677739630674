<div class="w-100">
  <div class="container search-bar">
    <form [formGroup]="searchForm">
      <div class="row ">
        <div class="col">
          <mat-form-field id="searchField" appearance="outline">
            <mat-label>Address</mat-label>
            <input matInput #placesRef="ngx-places" ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)" [(ngModel)]="fullAddress" formControlName="address"
              placeholder="ex. 1 Infinite Loop, Toronto, ON">
          </mat-form-field>
        </div>
      </div>
      <div class="row justify-content-md-center search-field-options mx-auto" *ngIf="!turnOffRadius">
        <div class="col field" appearance="fill">
          <mat-label class="me-2">Radius ({{ searchCriteria.search_radius }} km)</mat-label>
          <mat-slider style="width: 80%;" [max]="sliderOptions.maxRadius" [min]="sliderOptions.minRadius"
              [step]="sliderOptions.stepSize" [thumbLabel]="sliderOptions.thumbLabel" [tickInterval]="1"
              [(ngModel)]="searchCriteria.search_radius" [ngModelOptions]="{standalone: true}">
          </mat-slider>
        </div>
      </div>

      <div class="row search-field-options mx-auto">
        <div class="col">
          <mat-form-field class="field" appearance="fill">
            <mat-label>Bedrooms</mat-label>
            <select [(ngModel)]="searchCriteria.bedroom_count" formControlName="bedroom_count" matNativeControl required>
              <option value="Any">Any</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="field" appearance="fill">
            <mat-label>Washrooms</mat-label>
            <select [(ngModel)]="searchCriteria.washroom_count" formControlName="washroom_count" matNativeControl required>
              <option value="Any">Any</option>
              <option value="1">1</option>
              <option value="1.5">1.5</option>
              <option value="2">2</option>
              <option value="2.5">2.5</option>
              <option value="3">3</option>
              <option value="3.5">3.5</option>
              <option value="4">4</option>
              <option value="4.5">4.5</option>
              <option value="5">5+</option>
            </select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="field" appearance="fill">
            <mat-label>Property Type</mat-label>
            <select [(ngModel)]="searchCriteria.houseType" formControlName="houseType" matNativeControl required>
              <option value="HOUSE">House</option>
              <option value="APARTMENT">Apartment</option>
              <option value="MULTIPLEX">Multiplex</option>
              <option value="CONDO">Condo</option>
              <option value="TOWNHOUSE">Townhouse</option>
            </select>
          </mat-form-field>
        </div>
        <div class="col" *ngIf="!disableSearchButton">
          <button mat-button matSuffix mat-stroked-button aria-label="search" class="btn btn-primary searchButton"
            (click)="search.emit();" [disabled]="!searchForm?.valid">Search</button>
        </div>

      </div>
    </form>
  </div>
</div>
