import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { AppService } from '../common/app.service';
import { ConfigService } from '../common/config.service';
import { DefaultService } from '../common/default-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends DefaultService implements OnInit {

  public loggedIn = false;
  public loginSubscription = new BehaviorSubject(null);

  public loggedInState = [
    { key: 'firstName', propertyPath: 'landlord.firstName' },
    { key: 'lastName', propertyPath: 'landlord.lastName' },
    { key: 'landlordId', propertyPath: 'landlord.id' },
    { key: 'authorities', propertyPath: 'authorities' },
  ];

  constructor(private http: HttpClient, public appService: AppService) {
    super(new ConfigService().apiUrl);
  }
  ngOnInit(): void {
    this.loginSubscription.subscribe((loggedIn) => {
      if (loggedIn != null) {
        this.loggedIn = loggedIn;
      }
    });
  }

  public login(username, password) {
    return this.http.post(this.api() + 'public/auth/login', { username: username, password: password });
  }

  public register(registrationInfo) {
    return this.http.post(this.api() + 'public/auth/register', registrationInfo);
  }

  public forgotPassword(username) {
    return this.http.post(this.api() + 'public/auth/_forgotPassword', { username: username });
  }

  public resetPassword(token, password) {
    return this.http.post(this.api() + 'public/auth/resetPassword', { token: token, password : password });
  }


  /** Local Application State - Maybe refactor this into another class **/
  public getAuth() {
    return this.http.get(this.api() + 'auth/me');
  }

  public getAuthState() {
    return JSON.parse(localStorage.getItem("auth"));
  }

  public setState(callback) {
    _.forEach(this.loggedInState, (state) => {
      callback(state);
    });
  }

  public setLoginState(auth) {
    localStorage.setItem("auth", JSON.stringify(auth));
  }

  public setLogoutState() {
    localStorage.removeItem("auth");
    localStorage.removeItem('token');
    localStorage.removeItem('shareable-auth-token');
  }

}
