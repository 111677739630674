<mat-expansion-panel [expanded]="sectionExpansion">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <h3>{{ sectionTitle }}</h3>
    </mat-panel-title>
    <!-- <mat-panel-description>
      This is a summary of the content
    </mat-panel-description> -->
  </mat-expansion-panel-header>
  <field class="field" *ngFor="let field of sectionFields" [fieldDefinition]="field"></field>
  <div class="w-100 mt-3">
      <button mat-button class="pointer next-button" color="primary" (click)="onNext.emit()">{{ nextButtonText == null ? 'Next' : nextButtonText }}</button>
  </div>
</mat-expansion-panel>
