import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, throttleTime } from 'rxjs/operators'
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { SearchService } from './search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  public searchResults = {};
  public initialSearch = false;
  public searchThrottle = _.debounce(this.search, 300);

  public searchCriteria = {
    address: null,
    postal_code: null,
    city: null,
  };

  public toggle = {
    loading: false,
  };

  constructor(public searchService: SearchService) { }

  ngOnInit() {
    window['mixpanel'].track('Jumped to: Rent Search');
  }

  public search() {
    this.initialSearch = true;
    if (this.searchCriteria) {
      this.searchResults = {};
      this.toggle.loading = true;

      this.searchService.analyze(this.searchCriteria).subscribe((results) => {
        window['mixpanel'].track('Ran a search');
          this.searchResults = results;
        }, (err) => {
          this.toggle.loading = false;
        }, () => {
          this.toggle.loading = false;
      });

    }

  }

}
