import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  //Needs the slash at the end, all services expect that.
  public apiUrl = '/api/';

  constructor() { }

}
