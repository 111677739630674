<div class="row boxed-container secondary-boxed-container-color hover-toggle">
  <div class="col">
    <div>
      <h3 class="address-line d-inline-block"> {{ postalCodeOnly == true ? (property.postal_code | uppercase) : property.address}} </h3>
      <a (click)="deleteProperty(property)"><i *ngIf="ownProperty" class="hover d-inline-block delete-icon text-muted pointer fas fa-trash"></i></a>
      <span class="d-block text-muted mb-2">{{ property.city | titlecase }}</span>
    </div>
    <div class="extra-info d-block text-muted p-0 m-0">
      <div>{{ property.bedrooms }} Bedrooms</div>
      <div>{{ property.washrooms }} Bathrooms</div>
      <!-- <div> {{ property.startDate | date }} - {{ property.endDate | date }}</div> -->
    </div>
  </div>
  <div class="col price">
    <div class="d-block text-muted m-0">
      <div class="priceValue text-success w-100 m-0">${{ property.rent | number }}</div>
      <div class="perMonth text-muted">per Month</div>
    </div>
  </div>
</div>
