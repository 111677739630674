<div class="card login-form">
    <div class="card-header">
      <img class="logo" src="assets/logo.png" />
    </div>
    <div class="card-body">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="username">Username</label>
                <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControls.username.errors }" />
                <div *ngIf="submitted && formControls.username.errors" class="invalid-feedback">
                    <div *ngIf="formControls.username.errors.required">Username is required</div>
                </div>
            </div>
            <div class="form-group">
                <label for="password">Password</label>
                <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControls.password.errors }" />
                <div *ngIf="submitted && formControls.password.errors" class="invalid-feedback">
                    <div *ngIf="formControls.password.errors.required">Password is required</div>
                </div>
            </div>
            <div class="form-group">
                <button [disabled]="loading" class="btn login-button">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Login
                </button>
                <a routerLink="/register" class="btn btn-link">Register</a>
                <a routerLink="/forgotPassword" class="btn btn-link forgotPassLink">Forgot Password?</a>
            </div>
        </form>
    </div>
</div>