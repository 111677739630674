import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Location} from '@angular/common';
import { AuthService } from '../login/auth.service';


@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public menuItems = [{name: 'Dashboard', path:'/', icon: 'fas fa-briefcase'}, {name: 'My Properties', path:'/properties', icon: 'fas fa-house-user'}, {name: 'Rent Search', path:'/rent-search', icon: 'fas fa-search'}, {name: 'Analyzer', path:'/analysis', icon: 'fas fa-calculator'}];
  public currentTab = 'Dashboard';
  public username: string = '';
  public auth : any;

  constructor(public location: Location, public router: Router, public loginService: AuthService) {
        let url = this.location.path();
        switch(url){
          //case '/':
            //this.currentTab = 'Dashboard';
            //break;
          case this.findTerm('/properties', url):
            this.currentTab = 'My Properties';
            break;
          case this.findTerm('/analysis', url):
            this.currentTab = 'Analyzer';
            break;
          case this.findTerm('/search', url):
            this.currentTab = 'Rent Search';
            break;
          default:
            this.currentTab = 'Dashboard';
        }
  }

  public findTerm (term, url){
    if (url.includes(term)){
      return url;
    }
    return null;
  };


  ngOnInit() {
    this.auth = this.loginService.getAuthState();
    // if (this.auth?.landlord?.firstName != null && this.auth?.landlord?.lastName != null) {
    if (this.auth?.landlord?.firstName != null ) {
      this.username = this.auth?.landlord?.firstName + ' ' + ( this.auth?.landlord?.lastName == null ? '' : this.auth?.landlord?.lastName );
    }
    else {
      this.username = "";
    }
  }

  public logout() {
    this.loginService.setLogoutState();
    this.loginService.loginSubscription.next(false);
  }

  public goToMenu (item) {
    this.router.navigateByUrl(item.path);
    this.currentTab = item.name;
  }

}
