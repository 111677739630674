import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'calculator-section',
  templateUrl: './calculator-section.component.html',
  styleUrls: ['./calculator-section.component.scss']
})
export class CalculatorSectionComponent implements OnInit{

  @Input() sectionTitle;
  @Input() sectionFields: any;
  @Input() sectionFormGroup: FormGroup;
  @Input() sectionKey;
  @Input() nextButtonText;
  @Input() sectionExpansion = false;
  @Output() onNext = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }


}
