<div class="card recovery-form">
    <div class="card-header">
      <img class="logo" src="assets/logo.png" />
    </div>
    <div class="card-body">
      <h4>
        Your email is confirmed.
        <a routerLink="/login">Click here</a> to login
      </h4>
    </div>
</div>