import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AppService } from 'src/app/common/app.service';
import { AuthService } from 'src/app/login/auth.service';

@Component({
  selector: 'recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent implements OnInit {

  public recoveryForm: FormGroup;
  public formControls;
  public submitted = false;
  public token = null;

  constructor(public authService: AuthService, public appService: AppService, public formBuilder: FormBuilder, public notificationService: NotificationsService, private route: ActivatedRoute, public router: Router ) {

    this.recoveryForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
    this.formControls = this.recoveryForm.controls;
    this.route.queryParams.subscribe (params => {
      this.token = params['token'];
    });
  }
   

  ngOnInit() {
  }

  public onSubmit() {
    this.submitted = true;
    if (this.recoveryForm.invalid) {
      return;
    }

    if (!this.token) {
      this.notificationService.error('Please provide a valid recovery token');
    }


    if (this.formControls.password.value != null) {
      this.appService.loadingService.next(true);

        this.authService.resetPassword(this.token, this.formControls.password.value).subscribe((resetPass) => {
          this.notificationService.success('Password successfully reset');
          this.router.navigateByUrl('/login');
        }, (err)=> {
          this.notificationService.error('Could not reset password, try again!');
        }).add(() => {
          this.appService.loadingService.next(false);
        });

    }

  }

}
