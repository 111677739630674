import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClipboardService } from 'ngx-clipboard'

@Component({
  selector: 'app-shared-link-dialog',
  templateUrl: './shared-link-dialog.component.html',
  styleUrls: ['./shared-link-dialog.component.css']
})
export class SharedLinkDialogComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<SharedLinkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public clipboardService: ClipboardService) {

  }

  ngOnInit() {
  }

  public copyToClipboard(){
    this.clipboardService.copy(this.data.link);
    this.dialogRef.close();
  }

}
