import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import { AuthService } from '../login/auth.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

    //Gonna leave this interceptor here, but currently not used. Maybe for logging in the future
    constructor(public router: Router, public authService: AuthService) { }
        intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            return next.handle(req).pipe(map((event: HttpEvent<any>) => {
                return event;
            }));
    }

}
