import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { AppService } from '../common/app.service';
import { AuthService } from '../login/auth.service';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  @Input() public username: any;
  @Input() public state: any;
  @Output() public stateChange = new EventEmitter();

  public forgotPasswordForm: FormGroup;
  public formControls;
  public submitted = false;

  constructor(public appService: AppService, public authService: AuthService, public formBuilder: FormBuilder, public notificationsService: NotificationsService) { 
    this.forgotPasswordForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
    });
    this.formControls = this.forgotPasswordForm.controls;
  }

  ngOnInit() {
    if (this.username){
      this.formControls.username.value = this.username;
    }
  }

  public onSubmit() {
    this.submitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    if (this.formControls.username.value != null) {
      this.appService.loadingService.next(true);
        this.authService.forgotPassword(this.formControls.username.value).subscribe((forgotPassword) => {
          this.notificationsService.success('Forgot Password email sent!');
        }, (err)=> {
          this.notificationsService.error(
            'Could not send forgot password email',
            err.error.message,
         );
        }).add(() => {
          this.appService.loadingService.next(false);
        });

    }

  }

}
