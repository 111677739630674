import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../common/config.service';
import { DefaultService } from '../common/default-service';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService extends DefaultService  {
  
  constructor(private http: HttpClient) { 
    super(new ConfigService().apiUrl);
  }

  public getTotalNumberOfProperties() {
    return this.http.get(this.api() + 'statistics/totalProperties');
  }

  public getMyStatistics() {
    return this.http.get(this.api() + 'statistics/mine');
  }

}
