import { Component, OnInit } from '@angular/core';
import { AppService } from './common/app.service';
import { AuthService } from './login/auth.service';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'landlord-network';
  public options = {
    position: ["center", "top"],
    timeOut: 5000,
    lastOnBottom: true
  };

  public currentState = 'LOGIN';

  public toggle = {
    loading: false,
  };

  constructor(public loginService: AuthService, public appService: AppService, public location: Location, public router: Router) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {

        switch (this.location.path()) {
          case this.location.path().includes('/shared') ? this.location.path() : '/shared':
            this.currentState = 'SHARED';
            break;
          case '/register':
            this.currentState = 'REGISTRATION';
            break;
          case '/login':
            if (localStorage.getItem("token") != null) {
              this.router.navigateByUrl('/home');
              this.currentState = 'APP';
              return;
            }
            this.currentState = 'LOGIN';
            break;
          case '/forgotPassword':
            this.currentState = 'FORGOT_PASSWORD';
            break;
          case this.location.path().includes('/confirmation') ? this.location.path() : '/confirmation':
            this.currentState = 'CONFIRMATION';
            break;
          case this.location.path().includes('/recovery') ? this.location.path() : '/recovery':
            this.currentState = 'RECOVERY';
            break;
          default:
            if (localStorage.getItem("token") == null) {
              this.currentState = 'LOGIN';
              return;
            }

            this.currentState = 'APP';
            break;
        }
      }
    })



    this.loginService.loginSubscription.subscribe((loggedIn) => {
      if (loggedIn != null) {
        this.currentState = loggedIn ? 'APP' : 'LOGIN';
      }
    });

    this.appService.loadingService.subscribe((loading) => {
      if (loading != null) {
        this.toggle.loading = loading;
      }
    });


  }

  ngOnInit(): void {
  }

  public allValuesFalse(object: any) {
    console.log(_.values(object).every(val => val == false));
    return true;
  }




}
