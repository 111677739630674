<div class="w-100 home-body">
  <div class="container-fluid">
    <div class="row justify-content-md-center">
      <div class="col">
        <h6>Perform your analysis, all in one place</h6>
        <h1>Analyzer</h1>
      </div>
    </div>

    <div class="row justify-content-md-center" *ngIf="editMode && !sharedMode">
          <div class="row">
            <mat-accordion>
              <div class="mt-5 col-md-8 col-sm-12">
                    <search-bar [searchCriteria]="searchCriteria" (search)="rentHook($event)" [disableSearchButton]="true"></search-bar>
              </div>
              <div *ngFor="let sectionKey of sectionKeys" class="mt-5 col-md-8 col-sm-12">
                    <calculator-section [nextButtonText]="sections[sectionKey].buttonText" [sectionExpansion]="sections[sectionKey].accordianState" [sectionTitle]="sections[sectionKey].sectionTitle" [sectionFields]="sections[sectionKey].fields" [sectionFormGroup]="sections[sectionKey].formGroup" (onNext)="gotoNextPanel(sectionKey)"></calculator-section>
              </div>
            </mat-accordion>
         </div>
         <div class="row mt-5">
           <div class="col-md-8 col-sm-12">
              <!-- <button mat-button class="pointer mat-raised-button fs-6" color="primary"  (click)="this.onInit(); editMode = false;">Calculate</button> -->
              <a mat-button class="pointer mat-raised-button fs-6" color="primary" (click)="calculate();">Calculate</a>
           </div>
         </div>
   </div>

    <div class="row justify-content-md-center mt-4" *ngIf="!editMode || sharedMode">
          <div class="row gx-5">
            <div class="d-flex flex-row-reverse">
              <button mat-button class="mat-button mat-primary flex-shrink-1" (click)="editModeAddress = !editModeAddress;">{{ editModeAddress ? 'Save Address' : 'Edit Address'}}</button>
              <button *ngIf="enableSharing" mat-button class="mat-button mat-primary flex-shrink-1" (click)="getShareableLink()">Share Analysis</button>
            </div>
            <ng-container *ngIf="!editModeAddress">
              <div class="d-flex flex-row">
                <h4 class="fw-bold w-100">{{ searchCriteria?.address }}</h4>
              </div>
              <div class="text-muted fs-6">
                <!-- <span><i class="fas fa-map-marker-alt me-1"></i>{{ inputAnalysis?.propertyDetails?.city }}</span>
                <span><i class="fas fa-bed me-1 ms-5"></i>{{ inputAnalysis?.propertyDetails?.bedrooms }}</span>
                <span><i class="fas fa-toilet me-1 ms-3"></i>{{ inputAnalysis?.propertyDetails?.washrooms }}</span> -->

                <span><i class="fas fa-map-marker-alt me-1"></i>{{ searchCriteria.city }}</span>
                <span><i class="fas fa-bed me-1 ms-5"></i>{{ searchCriteria.bedroom_count }}</span>
                <span><i class="fas fa-toilet me-1 ms-3"></i>{{ searchCriteria.washroom_count }}</span>
              </div>
            </ng-container>
            <ng-container>
                <div class="mt-5 col-md-8 col-sm-12" *ngIf="editModeAddress">
                    <search-bar [searchCriteria]="searchCriteria" [turnOffRadius]="true" [disableSearchButton]="true" (search)="updatePropertyDetails($event)"></search-bar>
                    <!-- <button mat-button class="mat-raised-button mat-primary flex-shrink-1" (click)="editModeAddress = !editModeAddress;">Edit Address</button> -->
                 </div>
            </ng-container>
          </div>
          <div class="row">
            <div class="col pt-5 order-sm-2" *ngIf="output">
                <h4 class="fw-bold">Cash Flow</h4>
                <div class="row d-flex justify-content-around boxed-container secondary-boxed-container-color" *ngIf="output.cashOnCashJson">
                  <div class="col box" [ngClass]="output.cashOnCashJson.cashOnCashReturn < 0 ? 'text-danger' : 'text-success'">
                    <div>
                      {{ output.cashOnCashJson.cashOnCashReturn / 100 | percent }}
                      <div class="detail-name">
                         Cash on Cash Return
                      </div>
                    </div>
                  </div>
                  <div class="col box" [ngClass]="output.cashFlowJson.monthlyCashFlow < 0 ? 'text-danger' : 'text-success'">
                    <div>
                      {{ output.cashFlowJson.monthlyCashFlow | currency }}
                      <div class="detail-name">
                         Monthly Cash Flow
                      </div>
                    </div>
                  </div>
                  <div class="col box" [ngClass]="output.cashFlowJson.annualCashFlow  < 0 ? 'text-danger' : 'text-success'">
                    <div>
                      {{ output.cashFlowJson.annualCashFlow | currency }}
                      <div class="detail-name">
                         Annual Cash Flow
                      </div>
                    </div>
                  </div>
                </div>

                <h4 class="fw-bold mt-5">Long-term Investment</h4>
                  <div class="row d-flex justify-content-around boxed-container secondary-boxed-container-color" *ngIf="output.appreciationAnalysisJson">
                    <h6 class="fw-light mt-3">{{ this.inputAnalysis.analysisTimePeriod }} Years Period</h6>
                    <div class="col box" [ngClass]="output.appreciationAnalysisJson.salePrice < 0 ? 'text-danger' : 'text-success'">
                      <div>
                        {{ output.appreciationAnalysisJson.salePrice  | currency }}
                        <div class="detail-name">
                          Final Sale Price
                        </div>
                      </div>
                    </div>
                    <div class="col box" [ngClass]="'text-success'">
                      <div>
                        {{  ( output.appreciationAnalysisJson.mortgagePaydown)  | currency }}
                        <div class="detail-name">
                          Mortgage Paydown
                        </div>
                      </div>
                    </div>
                    <div class="col box" [ngClass]="'text-danger'">
                      <div>
                        {{  ( output.appreciationAnalysisJson.buyerClosingCosts  )  | currency }}
                        <div class="detail-name">
                          Buying Closing Costs
                        </div>
                      </div>
                    </div>
                    <div class="col box" [ngClass]="'text-danger'">
                      <div>
                        {{  ( output.appreciationAnalysisJson.sellingClosingCosts)  | currency }}
                        <div class="detail-name">
                          Selling Closing Costs
                        </div>
                      </div>
                    </div>
                    <div class="col box" [ngClass]="output.appreciationAnalysisJson.returnAfterSelling < 0 ? 'text-danger' : 'text-success'">
                      <div>
                        {{ (output.appreciationAnalysisJson.returnAfterSelling + output.appreciationAnalysisJson.mortgagePaydown) - output.appreciationAnalysisJson.sellingClosingCosts  | currency }}
                        <div class="detail-name">
                           Total Return
                        </div>
                      </div>
                    </div>
                    <div class="col box" [ngClass]="output.appreciationAnalysisJson.returnOnInvestment  < 0 ? 'text-danger' : 'text-success'">
                      <div>
                        {{ output.appreciationAnalysisJson.returnOnInvestment / 100 | percent }}
                        <div class="detail-name">
                          Return On Initial Investment
                        </div>
                      </div>
                    </div>
                    <div class="col box" [ngClass]="output.cashOnCashJson.netCash  < 0 ? 'text-danger' : 'text-success'">
                      <div>
                        {{ output.cashOnCashJson.netCash | currency }}
                        <div class="detail-name">
                          Total Cash Flow Over Period
                        </div>
                      </div>
                    </div>

                    <div class="col box" [ngClass]="output.appreciationAnalysisJson.appreciationRate < 0 ? 'text-danger' : 'text-success'">
                      <div>
                        {{ ( output.appreciationAnalysisJson.returnAfterSelling  + output.cashOnCashJson.netCash + output.appreciationAnalysisJson.mortgagePaydown) - output.appreciationAnalysisJson.sellingClosingCosts | currency }}
                        <div class="detail-name">
                           Total Return (including Cash Flows)
                        </div>
                      </div>
                    </div>

                    <div class="col box" [ngClass]="output.appreciationAnalysisJson.appreciationRate < 0 ? 'text-danger' : 'text-success'">
                      <div>
                        {{  ( output.appreciationAnalysisJson.returnOnInvestmentWithCashFlows / 100 ) | percent }}
                        <div class="detail-name">
                          Return On Initial Investment (including Cash Flows)
                        </div>
                      </div>
                    </div>

                    <h6 class="fw-light mt-3">Other valueable numbers</h6>
                    <!-- <div class="col-sm-3 box" [ngClass]="'text-danger'">
                      <div>
                        {{  ( output.appreciationAnalysisJson.buyerClosingCosts)  | currency }}
                        <div class="detail-name">
                          Buying Closing Costs
                        </div>
                      </div>
                    </div> -->
                    <div class="col box" [ngClass]="'text-success'">
                      <div>
                        {{  ( output.appreciationAnalysisJson.annualizedReturnOnInvesment / 100)  | percent }}
                        <div class="detail-name">
                          Compounded Annualized Return on Investment
                        </div>
                      </div>
                    </div>

                    <div class="col box" [ngClass]="'text-success'">
                      <div>
                        {{  ( output.appreciationAnalysisJson.annualizedReturnOnInvesmentWithCashFlows / 100)  | percent }}
                        <div class="detail-name">
                          Compounded Annualized Return on Investment with Cash Flows
                        </div>
                      </div>
                    </div>
                    <!-- Calculation under 17% breaks? -->
                    <div *ngIf="output.appreciationAnalysisJson.cmhcInsurance > 0 " class="col-sm-3 box" [ngClass]="'text-danger'">
                      <div>
                        {{  ( output.appreciationAnalysisJson.cmhcInsurance)  | currency }}
                        <div class="detail-name">
                         CHMC Insurance
                        </div>
                      </div>
                    </div>

                    <div *ngIf="output.landTransferTaxCalculationJson.rebateAmount > 0 " class="col box" [ngClass]="'text-success'">
                      <div>
                        {{  ( output.landTransferTaxCalculationJson.rebateAmount)  | currency }}
                        <div class="detail-name">
                          Land Transfer Tax Rebate
                        </div>
                      </div>
                    </div>

                  </div>

                <expenses-chart></expenses-chart>


            </div>

            <div class="col pt-5 text-center mt-5 order-sm-2" *ngIf="!output">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Calculating...</span>
              </div>
              <h1 class="d-inline-block ms-3 text-muted">Calculating</h1>
            </div>



            <div class="d-block d-sm-none">
              <div class="my-5"><hr/></div>
            </div>


            <div class="col pt-5 order-sm-1">
                <div class="row">
                  <h4 class="fw-bold">Breakdown</h4>
                  <div class="boxed-container primary-boxed-container-color col-sm-6 mt-3">
                    <h5 class="fw-light">Overall</h5>
                    <calculator-display [forceNegative]="true" [fields]="outputSections['overall'].fields"></calculator-display>
                  </div>
                  <div class=" boxed-container primary-boxed-container-color col-sm-6 mt-3">
                    <h5 class="fw-light">Mortgage</h5>
                    <calculator-display [forceNegative]="true" [fields]="outputSections['mortgage'].fields" [outputs]="outputSections['mortgage'].outputs"></calculator-display>
                  </div>
                  <div class="boxed-container primary-boxed-container-color col-sm-6 mt-3">
                    <h5 class="fw-light">Monthly Expenses</h5>
                    <calculator-display [forceNegative]="true" [fields]="outputSections['monthlyExpenses'].fields" [outputs]="outputSections['monthlyExpenses'].outputs"></calculator-display>
                  </div>
                  <!-- <div class="col-sm-12 mt-5">
                    <h5 class="fw-light">Annual Expenses</h5>
                    <calculator-display [forceNegative]="true" [fields]="outputSections['annualExpenses'].fields" [outputs]="outputSections['annualExpenses'].outputs"></calculator-display>
                  </div> -->
                  <div class="boxed-container primary-boxed-container-color col-sm-6 mt-3">
                    <h5 class="fw-light">Monthly Revenue</h5>
                    <calculator-display [fields]="outputSections['monthlyRevenue'].fields" [outputs]="outputSections['monthlyRevenue'].outputs"></calculator-display>
                  </div>
                  <div class="boxed-container primary-boxed-container-color col-sm-6 mt-3">
                    <h5 class="fw-light">Investment</h5>
                    <calculator-display [forceNegative]="true" [fields]="outputSections['investment'].fields" [outputs]="outputSections['investment'].outputs"></calculator-display>
                  </div>
                </div>

                <!-- <div class="row mt-5">
                  <h4 class="fw-bold">Investment</h4>
                  <div class="col-sm-12 mt-3">
                    <calculator-display [forceNegative]="true" [fields]="outputSections['investment'].fields" [outputs]="outputSections['investment'].outputs"></calculator-display>
                  </div>
                </div> -->
            </div>



         </div>
   </div>

</div>
