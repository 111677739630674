import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { MyPropertiesComponent } from './my-properties/my-properties.component';
import { SearchComponent } from './search/search.component';
import { AnalysisListComponent } from './calculator/analysis/analysis-list/analysis-list.component';
import { AnalysisDetailComponent } from './calculator/analysis/analysis-detail/analysis-detail.component';
import { ShareableComponent } from './shareable/shareable.component';
import { SharedLinkDialogComponent } from './shared/shared-link-dialog/shared-link-dialog.component';

const routes: Routes = [
  { path: 'shared',
    children: [
      {path: 'analysis',
        children: [
          { path: ':code', component: ShareableComponent},
        ]
      },
    ]
  },
  { path: 'home', component: HomeComponent },
  { path: 'calculator', component: CalculatorComponent },
  { path: 'analysis',
    children: [
      {path: '', component: AnalysisListComponent},
      {path: ':id', component: AnalysisDetailComponent,
        children: [
          {path: 'initialize', component: AnalysisDetailComponent}
        ]
      },
    ]
  },
  { path: '', component: HomeComponent },
  { path: 'properties', component: MyPropertiesComponent},
  { path: 'rent-search', component: SearchComponent},
  { path: 'register', component: HomeComponent},
  { path: 'login', component: HomeComponent},
  { path: 'forgotPassword', component: HomeComponent},
  { path: 'recovery', component: HomeComponent},
  { path: 'confirmation', component: HomeComponent},
];

export const AppRoutes = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });
