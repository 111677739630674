import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NotificationsService } from "angular2-notifications";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "../login/auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(public authService: AuthService, public router: Router, public notificationService: NotificationsService) { }

    handleError(error: HttpErrorResponse, authService: any, router: any, notificationService: any) {
        if (error.status == 401) {
            localStorage.removeItem('token');
            this.notificationService.error(
                error.error.status,
                error.error.message,
            );
            this.router.navigateByUrl('/login');
        }
        return throwError(error);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token: string = localStorage.getItem('token');
        const shareableToken: string = localStorage.getItem('shareable-auth-token');

        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
            if (this.authService?.getAuthState() != null) {
                window['mixpanel'].identify(this.authService.getAuthState().landlord.id);
                window['mixpanel'].register(
                {
                    'Username': this.authService.getAuthState().landlord.username,
                    'Email': this.authService.getAuthState().landlord.username
                }
                );
            }
        }

        if (shareableToken) {
            request = request.clone({ headers: request.headers.set('Shareable-Token', shareableToken) });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(catchError((err) => this.handleError(err, this.authService, this.router, this.notificationService)));
    }

}
