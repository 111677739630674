<ng-container class="field-adjustment">

  <!-- Normal Text Field -->
  <mat-form-field appearance="standard" *ngIf="fieldDefinition.type == 'input'">
    <mat-label>{{ fieldDefinition.label }}</mat-label>
    <input matInput type="number" [ngModel]="value" (ngModelChange)="valueChanged($event)" ngDefaultControl>
    <span matSuffix>
      <button mat-button *ngIf="fieldDefinition?.supportedTypes?.includes('percent')" [ngClass]="fieldDefinition.currentUnitType == 'percent' ? 'mat-primary' : 'mat-secondary'" (click)="switchUnitType('percent')">%</button>
      <!-- <button mat-button *ngIf="fieldDefinition?.supportedTypes?.includes('currency')" [ngClass]="fieldDefinition.currentUnitType == 'currency' ? 'mat-primary' : 'mat-secondary'" (click)="switchUnitType('currency')">$</button> -->
    </span>
    <mat-hint *ngIf="fieldDefinition.hint">{{ fieldDefinition.hint }}</mat-hint>
  </mat-form-field>

  <!-- Dropdown Field -->
  <mat-form-field appearance="standard" *ngIf="fieldDefinition.type == 'dropdown'">
    <mat-label>{{ fieldDefinition.label }}</mat-label>
    <mat-select [ngModel]="value" (ngModelChange)="valueChanged($event)" ngDefaultControl>
      <mat-option *ngFor="let option of fieldDefinition.options" [value]="option">{{option}}{{fieldDefinition.unit}}</mat-option>
    </mat-select>
    <mat-hint *ngIf="fieldDefinition.hint">{{ fieldDefinition.hint }}</mat-hint>
  </mat-form-field>

  <!-- Display Field -->
  <mat-form-field appearance="standard" *ngIf="fieldDefinition.type == 'display'">
    <mat-label>{{ fieldDefinition.label }}</mat-label>
    <!-- <input matInput type="number" [disabled]="true" [ngModel]="value" (ngModelChange)="valueChanged($event)" ngDefaultControl> -->
    <!-- <input matInput type="number" [disabled]="true" [ngModel]="fieldDefinition.value" ngDefaultControl> -->
    {{ value }}
    <mat-hint *ngIf="fieldDefinition.hint">{{ fieldDefinition.hint }}</mat-hint>
  </mat-form-field>

</ng-container>
