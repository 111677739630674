<div class="w-100 home-body">
  <div class="container-fluid">
    <div class="row justify-content-md-center">
      <div class="col">
        <h6>Perform your analysis, all in one place</h6>
        <h1>Analyzer</h1>
      </div>
    </div>

    <div class="d-flex row justify-content-end">
      <span class="col-sm-2 mb-4 d-flex flex-row-reverse">
        <button
          mat-fab
          color="primary"
          class="justify-content-end"
          (click)="addAnalysis()"
        >
          <mat-icon>add</mat-icon>
        </button>

        <button
          mat-fab
          color="primary"
          class="justify-content-end me-2"
          (click)="deleteSelected()"
          [disabled]="selection.selected.length == 0"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </span>
    </div>

    <table
      [hidden]="!(dataSource.data.length > 0)"
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z8 w-100"
      matSort
      matSortActive="analysisName"
      matSortDirection="asc"
      matSortDisableClear
    >
      <ng-container matColumnDef="analysisName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <mat-checkbox
            class="me-2"
            (click)="$event.stopPropagation()"
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
          Analysis Name
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            class="me-2"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(element) : null"
            [checked]="selection.isSelected(element)"
          >
          </mat-checkbox>
          <a [routerLink]="['/analysis/', element.id]">{{
            element.analysisName
          }}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="analysis.propertyDetails.address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
        <td mat-cell *matCellDef="let element">
          {{ element.analysis.propertyDetails?.address }}
        </td>
      </ng-container>

      <ng-container matColumnDef="analysis.propertyDetails.propertyPrice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>List Price</th>
        <td mat-cell *matCellDef="let element" [ngClass]="'text-success'">
          {{ element.analysis.propertyDetails?.propertyPrice | currency }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
      </tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay"></tr>
    </table>

    <div *ngIf="dataSource.data.length == 0" class="text-center">
      <p class="display-6">You currently have no analysis</p>
      <p class="h6">Click the + icon to get started.</p>
    </div>

    <!-- <div *ngFor="let analysis of listOfAnalysis">
      {{ analysis | json }} <button (click)="deleteAnalysis(analysis)">Delete</button>
    </div> -->
  </div>
</div>
