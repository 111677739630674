<div class="page-container" *ngIf="currentState == 'APP' && !toggle.loading">
<!--   <div class="w-100 root-header">
    <header class="h-100"></header>
  </div> -->
  <div class="middle-container">
    <div class="root-menu">
      <menu class="h-100 p-0 m-0"></menu>
    </div>
    <div class="main">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="w-100 root-footer">
    <footer class="h-100"></footer>
  </div>
</div>
<div *ngIf="currentState == 'LOGIN' && !toggle.loading" class="d-flex justify-content-center">
  <login class="loginBody" [(state)]="state"></login>
</div>
<div *ngIf="currentState == 'REGISTRATION' && !toggle.loading" class="d-flex justify-content-center">
  <register class="loginBody" [(state)]="state"></register>
</div>
<div *ngIf="currentState == 'FORGOT_PASSWORD' && !toggle.loading" class="d-flex justify-content-center">
  <forgot-password class="loginBody" [(state)]="state"></forgot-password>
</div>
<div *ngIf="currentState == 'RECOVERY' && !toggle.loading" class="d-flex justify-content-center">
  <recovery class="loginBody" [(state)]="state"></recovery>
</div>
<div *ngIf="currentState == 'CONFIRMATION' && !toggle.loading" class="d-flex justify-content-center">
  <registration-confirmation class="loginBody" [(state)]="state"></registration-confirmation>
</div>
<div *ngIf="currentState == 'SHARED' && !toggle.loading" class="d-flex justify-content-center">
  <shareable class="shareable-body" [(state)]="state"></shareable>
</div>
<div class="d-flex justify-content-center spinner" *ngIf="toggle.loading">
  <mat-spinner></mat-spinner>
</div>
<simple-notifications [options]="options"></simple-notifications>
<div class="w-100">
    <div class="beta-text">
        BETA
    </div>
</div>
