import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { hammerjs } from "hammerjs/hammer";
import { ElementRef, Renderer2 } from "@angular/core";
import * as _ from "lodash";
import { SearchService } from "../search.service";

@Component({
  selector: "search-bar",
  templateUrl: "./search-bar.component.html",
  styleUrls: ["./search-bar.component.scss"],
})
export class SearchBarComponent implements OnInit, OnChanges {
  @Input("searchCriteria") searchCriteria;
  @Input("turnOffRadius") turnOffRadius = false;
  @Input("disableSearchButton") disableSearchButton = false;
  @Output() public search = new EventEmitter();
  @ViewChild("placesRef") placesRef: ElementRef;

  public defaultSearchCriteria = {
    search_radius: 1,
    bedroom_count: 1,
    washroom_count: 1,
    houseType: "HOUSE",
    address: null,
    postal_code: null,
    city: null,
  };

  public fullAddress = "";

  public sliderOptions = {
    maxRadius: 150,
    minRadius: 1,
    stepSize: 2,
    thumbLabel: true,
  };

  public searchForm: FormGroup;
  public postalCodes: {};
  public invertedCodes: {};

  constructor(
    public formBuilder: FormBuilder,
    public searchService: SearchService,
    public elementRef: ElementRef
  ) {
    this.searchService.postalCodeMap().subscribe((codes) => {
      this.postalCodes = codes;
      this.invertedCodes = _.invert(this.postalCodes);
    });
  }

  ngAfterViewInit(): void {
    //This fixes the issue with the search address suggestions showing up weird on mobile.
    //Currently don't love this solution because it's based off timing. Should eventually move to something else.
    setTimeout(() => {
      let searchField = document.querySelector(
        "#searchField .mat-form-field-wrapper"
      );
      let autoCompleteBox = document.querySelector(".pac-container");
      searchField.append(autoCompleteBox);
    }, 500);
  }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      address: ["", { validators: [Validators.required], updateOn: "change" }],
      houseType: [
        "HOUSE",
        { validators: [Validators.required], updateOn: "change" },
      ],
      bedroom_count: [
        "Any",
        { validators: [Validators.required], updateOn: "change" },
      ],
      washroom_count: [
        "Any",
        { validators: [Validators.required], updateOn: "change" },
      ],
    });

    this.searchForm.valueChanges.subscribe((value) => {
      if (this.searchForm.valid && this.searchCriteria.postal_code != null) {
        this.search.emit(this.searchCriteria);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchCriteria.currentValue != null) {
      this.initilizeDefaults(changes.searchCriteria);
      if (
        changes.searchCriteria.currentValue.address != null &&
        changes.searchCriteria.currentValue.city != null
      ) {
        this.fullAddress =
          changes.searchCriteria.currentValue.address +
          ", " +
          changes.searchCriteria.currentValue.city;
      }
    }
  }

  public initilizeDefaults(searchCriteria: any) {
    _.merge(this.searchCriteria, this.defaultSearchCriteria);
  }

  public handleAddressChange($event) {
    window["mixpanel"].track("Inputted an address on search");
    let streetNumber = _.find($event.address_components, {
      types: ["street_number"],
    });
    let street = _.find($event.address_components, { types: ["route"] });
    let city = _.find($event.address_components, { types: ["locality"] });
    if (!city) {
      city = _.find($event.address_components, {
        types: ["administrative_area_level_3"],
      });
    }
    let correspondingPostalCode = this.findPostalCodeFromCity(
      city.long_name
    )[0];
    let postalCode = _.find($event.address_components, {
      types: ["postal_code"],
    });
    if (streetNumber != null && street != null) {
      this.searchCriteria.address =
        streetNumber.long_name + " " + street.long_name;
    } else {
      this.searchCriteria.address = "";
    }
    this.searchCriteria.city = city.long_name;
    this.searchCriteria.postal_code =
      postalCode != null ? postalCode.long_name : correspondingPostalCode;

    //Should probably bring in these later on
    let province = _.find($event.address_components, {
      types: ["administrative_area_level_1"],
    });
    let country = _.find($event.address_components, { types: ["country"] });

    if (this.searchForm.valid && this.searchCriteria.postal_code != null) {
      this.search.emit(this.searchCriteria);
    }
  }

  public findPostalCodeFromCity(city) {
    return _.filter(this.invertedCodes, (v, k) => {
      return _.includes(k, city);
    });
  }
}
