import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AddPropertyComponent } from '../modals/add-property/add-property.component';
import { MyPropertiesService } from './my-properties.service';
import * as _ from 'lodash';
import { AuthService } from '../login/auth.service';

@Component({
  selector: 'my-properties',
  templateUrl: './my-properties.component.html',
  styleUrls: ['./my-properties.component.scss']
})
export class MyPropertiesComponent implements OnInit {

  public properties = [];
  public toggle = {
    loading: false
  };

  constructor(public authService: AuthService, public propertiesService: MyPropertiesService, public dialog: MatDialog) { }

  ngOnInit() {
    window['mixpanel'].track('Jumped to: My Properties');
    this.getProperties();
  }

  public addProperty() {
    this.dialog.open(AddPropertyComponent, {
      data: {test: 'hello'},
      width: '800px',
      height: '500px',
      position: {
        top: ((window.innerHeight/2) - 350) + 'px',
        left: ((window.innerWidth/2) - 300) + 'px',
      }
    }).afterClosed().subscribe((property)=> {
      if (property != null){
        property.landlordId = this.authService.getAuthState().landlordId;
        this.properties.push(property);
        this.propertiesService.saveProperty(property).subscribe((prop:any) => {
          window['mixpanel'].track('Added Own Property');
        }, (error) => {
          this.properties.splice(this.properties.length - 1);
        });
      }
    });
  }

  public getProperties(){
    this.toggle.loading = true;
    this.propertiesService.getProperties().subscribe((properties: []) => {
      this.properties = properties
      this.properties = _.reverse(_.sortBy(this.properties, 'rent'));
    }).add(() => {
      this.toggle.loading = false;
    });
  }

  public deleteProperty(property){
    window['mixpanel'].track('Deleted Own Property');
    this.propertiesService.deleteProperty(property.id).subscribe(() => {
      this.properties = _.remove(this.properties, (prop) => {prop.id == property.id});
    }, (err) => {
      this.properties.push(property);
    });
  }

}
