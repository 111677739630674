import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../common/config.service';
import { DefaultService } from '../common/default-service';


@Injectable({
  providedIn: 'root'
})
export class MyPropertiesService extends DefaultService {

  constructor(private http: HttpClient) {
    super(new ConfigService().apiUrl);
  }

  public getProperties() {
    return this.http.get(this.api() + 'property/mine');
  }

  public saveProperty(property) {
    return this.http.post(this.api() + 'property/', property);
  }

  public deleteProperty(propertyId) {
    return this.http.delete(this.api() + 'property/' + propertyId);
  }

}
