<div class="w-100 home-body">
  <div class="container header">
    <div class="row justify-content-md-center">
      <div class="col">
        <h6>All your data, all in one place.</h6>
        <h1>My Properties</h1>
      </div>
      <div class="col">
        <div class="menu-options hover-toggle">
          <button class="option" (click)="addProperty()">Add Property</button>
        </div>
      </div>
    </div>

    <div class="row justify-content-md-center">
      <div class="col">
        <div class="" *ngIf="!toggle.loading">
          <div *ngIf="!properties.length" class="boxed-container primary-boxed-container-color">
            <h2 class="text-center">No properties currently added.</h2>
            <h3 class="text-center">Click 'Add Property' to add one.</h3> 
          </div>
          <div class="property-container" *ngIf="properties.length > 0">
            <property-overview *ngFor="let property of properties" (deleteProp)="deleteProperty($event)" [property]="property" [ownProperty]="true"></property-overview>
          </div>
        </div>
      </div>
    </div>

  <div class="help-text" *ngIf="toggle.loading">
    Loading...
  </div>

  </div>
</div>