<div class="card recovery-form">
    <div class="card-header">
      <img class="logo" src="assets/logo.png" />
    </div>
    <div class="card-body">
        <form [formGroup]="recoveryForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label ng-required="true" for="password">New Password</label>
                <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControls.password.errors }" />
                <div *ngIf="submitted && formControls.password.errors" class="invalid-feedback">
                    <div *ngIf="formControls.password.errors.required">Password is required</div>
                </div>
            </div>
            <div class="form-group">
                <button class="btn register-button">
                   Reset Password 
                </button>
                <a routerLink="/login" class="btn btn-link">Login</a>
            </div>
        </form>
    </div>
</div>