<div class="dialog">
  <h2 mat-dialog-title>Add Your Property</h2>
  <mat-dialog-content class="content w-100 overflow-hidden">
  <form [formGroup]="newPropertyForm" >
    <div class="row justify-content-md-center">
      <mat-form-field id="searchField" class="col-12" appearance="outline">
        <mat-label>Address</mat-label>
        <input matInput ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)" [(ngModel)]="fullAddress" formControlName="address"
        placeholder="eg. 1 Infinite Loop, Toronto, ON">
      </mat-form-field>
      <mat-form-field id="searchField" class="col-12" appearance="outline">
        <mat-label>Address Line 2</mat-label>
        <input matInput [(ngModel)]="property.address_line_two" formControlName="addressLineTwo"
        placeholder="eg. Unit 2, Suite 2, etc (if applicable)">
      </mat-form-field>
      <!-- <mat-form-field class="m-2" appearance="outline" >
      <mat-label>Street Name</mat-label>
      <input matInput [(ngModel)]="property.address" formControlName="address" placeholder="Street Name">
      <mat-hint>Include only the street name</mat-hint>
      </mat-form-field>
      <mat-form-field class="m-2" appearance="outline" >
      <mat-label>City</mat-label>
      <input matInput [(ngModel)]="property.city" formControlName="city" placeholder="City">
      </mat-form-field> -->
    </div>
    <div class="row">
      <mat-form-field class="col" appearance="outline">
      <mat-label>Rented Price</mat-label>
      <input matInput [(ngModel)]="property.rent" formControlName="rent" placeholder="Rented Price">
      <!-- <mat-hint>The final rent price</mat-hint> -->
      </mat-form-field>
      <mat-form-field class="col" appearance="fill">
      <mat-label>Bedrooms</mat-label>
      <select [(ngModel)]="property.bedrooms" formControlName="bedrooms" matNativeControl required>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
      </select>
      </mat-form-field>
      <mat-form-field class="col" appearance="fill">
      <mat-label>Bathrooms</mat-label>
      <select [(ngModel)]="property.washrooms" formControlName="bathrooms" matNativeControl required>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
      </select>
      </mat-form-field>
    </div>
    <div class="row mb-3">
      <mat-form-field class="col field" appearance="fill">
      <mat-label>Property Type</mat-label>
      <select [(ngModel)]="property.houseType" formControlName="houseType" matNativeControl required>
        <option value="HOUSE">House</option>
        <option value="APARTMENT">Apartment</option>
        <option value="MULTIPLEX">Multiplex</option>
        <option value="CONDO">Condo</option>
        <option value="TOWNHOUSE">Townhouse</option>
      </select>
      </mat-form-field>
      <mat-form-field class="col" appearance="fill">
        <mat-label>Choose a start rent date</mat-label>
        <input matInput [(ngModel)]="property.startDate" [matDatepicker]="start" formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
        <mat-datepicker #start></mat-datepicker>
      </mat-form-field>
    </div>
    <!-- <div class="w-100"> -->
    <!-- <mat-form-field class="m-2" appearance="fill">
    <mat-label>Choose a end rent date</mat-label>
    <input matInput [(ngModel)]="property.endDate" [matDatepicker]="end" formControlName="endDate">
    <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
    <mat-datepicker #end></mat-datepicker>
    </mat-form-field> -->
    <!-- </div> -->
  </form>
  </mat-dialog-content>
  <mat-dialog-actions class="footer d-flex flex-row-reverse">
  <button class="btn btn-primary ml-3 primary" mat-button [mat-dialog-close]="property" [disabled]="!newPropertyForm?.valid || property.address == null" cdkFocusInitial>Add Property</button>
  <a class="btn text-muted" at-button mat-dialog-close>Cancel</a>
  </mat-dialog-actions>
</div>
