import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormsModule } from '@angular/forms';

@Component({
  selector: "analysis-name-modal",
  templateUrl: "./analysis-name-modal.component.html",
  styleUrls: ["./analysis-name-modal.component.scss"],
})
export class AnalysisNameModalComponent implements OnInit {

  public name: string = '';

  constructor(
    public dialogRef: MatDialogRef<AnalysisNameModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {}) {
  }

  ngOnInit() {}

  public saveName() {
    this.dialogRef.close(this.name);
  }
}
