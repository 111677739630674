
export class DefaultService {
  public apiUrl = ''

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  public api(){
      return this.apiUrl;
  }

}
