import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../common/config.service';
import { DefaultService } from '../common/default-service';

@Injectable({
  providedIn: 'root'
})
export class MortgageService extends DefaultService {

  constructor(private http: HttpClient) {
    super(new ConfigService().apiUrl);
  }

  public mortgageRates() {
    return this.http.get(this.api() + 'mortgage/rates');
  }

}
