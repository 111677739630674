<div *ngFor="let field of fields">
  <ng-container *ngIf="!field.editable && field.type != 'display'">
    <label class="text-muted">{{field.label}}</label>  <span class="d-inline-block ms-3 fw-bold">{{ convertFieldValue(field) }}</span>
  </ng-container>
  <ng-container *ngIf="field.editable && !field.type != 'display'">
    <field class="small-fields" [fieldDefinition]="field"></field>
  </ng-container>
  <ng-container *ngIf="field.type == 'display'">
    <label class="text-muted">{{ field.label }}</label>  <span class="fw-bold" [ngClass]="forceNegative || field.value < 0  ? 'text-danger' : 'text-success'"> {{ field.value() | currency  }} </span>
  </ng-container>
</div>
<div class="total" *ngIf="outputs?.length > 0">
  <div *ngFor="let output of outputs">
    <label class="text-muted">{{ output.name }}</label>  <span class="fw-bold" [ngClass]="forceNegative || output.value < 0  ? 'text-danger' : 'text-success'"> {{ convertType(output.value, output.fieldType, output.valueKey) }} </span>
  </div>
</div>

<div class="total" *ngIf="totalField">
  <label class="text-muted">{{ totalField.name }}</label> <span class="fw-bold" [ngClass]="forceNegative || totalField.value < 0  ? 'text-danger' : 'text-success'"> {{ convertType(totalField.value, totalType) }} </span>
</div>
