<div class="card register-form">
    <div class="card-header">
      <img class="logo" src="assets/logo.png" />
    </div>
    <div class="card-body">
        <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label ng-required="true" for="firstName">First Name</label>
                <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControls.firstName.errors }" />
                <div *ngIf="submitted && formControls.firstName.errors" class="invalid-feedback">
                    <div *ngIf="formControls.firstName.errors.required">First Name is required</div>
                </div>
            </div>
            <div class="form-group">
                <label ng-required="true" for="username">Email</label>
                <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControls.username.errors }" />
                <div *ngIf="submitted && formControls.username.errors" class="invalid-feedback">
                    <div *ngIf="formControls.username.errors.required">Email is required</div>
                    <div *ngIf="formControls.username.errors.email">Must be in email format</div>
                </div>
            </div>
            <div class="form-group">
                <label ng-required="true" for="password">Password</label>
                <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControls.password.errors }" />
                <div *ngIf="submitted && formControls.password.errors" class="invalid-feedback">
                    <div *ngIf="formControls.password.errors.required">Password is required</div>
                    <div *ngIf="formControls.password.errors.minlength">Must be at least 8 characters</div>
                </div>
            </div>
            <!--
            <div class="form-group">
                <label ng-required="true" for="lastName">Last Name</label>
                <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControls.lastName.errors }" />
                <div *ngIf="submitted && formControls.lastName.errors" class="invalid-feedback">
                    <div *ngIf="formControls.lastName.errors.required">Last Name is required</div>
                </div>
            </div>
            <div class="form-group">
                <label ng-required="false" for="phone">Phone #</label>
                <input type="text" formControlName="phone" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControls.phone.errors }" />
            </div> -->
            <!-- <div class="form-group form-check"> -->
            <div class="">
                <!-- <input type="checkbox" formControlName="termsCheckbox" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && formControls.termsCheckbox.errors }" /> -->
                <!-- <label class="form-check-label" ng-required="true" for="termsCheckbox">By registering you agree have read and agree to the <a target="_blank" href="https://www.spotr.ca/terms-of-service.html">Terms and Conditions</a> , <a target="_blank" href="https://www.spotr.ca/privacy-policy.html">Privacy Policy</a>, and <a target="_blank" href="https://www.spotr.ca/cookie-policy.html">Cookie Policy</a></label> -->
                <label class="" for="termsCheckbox">By registering you agree have read and agree to the <a target="_blank" href="https://www.spotr.ca/terms-of-service.html">Terms and Conditions</a> , <a target="_blank" href="https://www.spotr.ca/privacy-policy.html">Privacy Policy</a>, and <a target="_blank" href="https://www.spotr.ca/cookie-policy.html">Cookie Policy</a></label>
                <div *ngIf="submitted && formControls.termsCheckbox.errors" class="invalid-feedback">
                    <div *ngIf="formControls.termsCheckbox.errors.required">Need to agree to the terms agreement.</div>
                </div>
            </div>
            <div class="form-group">
                <button class="btn register-button">
                    Register
                </button>
                <a routerLink="/login" class="btn btn-link">Login</a>
            </div>
        </form>
    </div>
</div>
