<div class="menu ">
	<div class="logoCompartment">
		<img class="logo" src="assets/logo_white.png" />
	</div>

	<div class="user-name" *ngIf="username">
    {{ username }}
	</div>

 	<div class="menu-item pointer" [ngClass]="{ selected: item.name == currentTab }" *ngFor="let item of menuItems" (click)="goToMenu(item)">
		<i class="icon" [ngClass]="item.icon"></i>
		<span class="menu-text">{{item.name}}</span><i class="menu-arrow fas fa-long-arrow-alt-right"></i>
	</div>


	<a (click)="logout()" class="logout-button">
		<div class="logout-button-container">
			Log Out &nbsp;<i class="fas fa-sign-out-alt"></i>
		</div>
	</a>



</div>
