import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SELECT_PANEL_INDENT_PADDING_X } from "@angular/material";
import { Router } from "@angular/router";
import { NotificationsService } from "angular2-notifications";
import * as _ from "lodash";
import { AppService } from "../common/app.service";
import { AuthService } from "../login/auth.service";

@Component({
  selector: "register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  @Input() public state: any;
  @Output() public stateChange = new EventEmitter();

  public registrationForm: FormGroup;
  public formControls;
  public submitted = false;

  constructor(
    public appService: AppService,
    public loginService: AuthService,
    public formBuilder: FormBuilder,
    public router: Router,
    public notificationsService: NotificationsService
  ) {
    this.registrationForm = this.formBuilder.group({
      username: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(8)]],
      firstName: ["", Validators.required],
      // lastName: ['', Validators.required],
      // phone: ['',],
      // termsCheckbox: ['',Validators.required],
    });
    this.formControls = this.registrationForm.controls;
    window["mixpanel"].track("Jumped to: Register Page");
  }

  ngOnInit() {}

  public onSubmit() {
    this.submitted = true;
    if (this.registrationForm.invalid) {
      window["mixpanel"].track("Typed on register page", {
        form: this.registrationForm,
      });
      return;
    }

    if (this.isFormValid()) {
      let registrationInfo = this.getRegistrationInfo();
      this.appService.loadingService.next(true);
      this.loginService.register(registrationInfo).subscribe(
        (newUser) => {
          window["mixpanel"].track("Successfully registered");
          this.loginService
            .login(registrationInfo["username"], registrationInfo["password"])
            .subscribe((token: any) => {
              localStorage.setItem("token", token.token);

              this.loginService.getAuth().subscribe((auth: any) => {
                this.loginService.setLoginState(auth);

                this.router.navigateByUrl("/home").then(
                  (navigation) => {
                    this.appService.loadingService.next(false);
                    this.loginService.loginSubscription.next(true);
                  },
                  (err) => {
                    this.appService.loadingService.next(false);
                  }
                );
              });
            });
        },
        (error) => {
          if (error.status == 409) {
            this.notificationsService.error(
              "User already exists",
              "Seems like you already exists in our system. Try logging in!"
            );
          }

          this.appService.loadingService.next(false);
        }
      );
    }
  }

  public isFormValid() {
    let controls = this.getRegistrationInfo();
    // return controls['username'] && controls['password'] && controls['firstName'] && controls['lastName'] && controls['termsCheckbox'];
    return (
      controls["username"] && controls["password"] && controls["firstName"]
    );
  }

  public getRegistrationInfo() {
    let controls = this.getRegistrationControls();
    return {
      username: controls[0],
      password: controls[1],
      firstName: controls[2],
      // lastName: controls[3],
      // phone: controls[4],
      // termsCheckbox: controls[5],
    };
  }

  public getRegistrationControls() {
    let username = this.registrationForm.controls.username.value;
    let password = this.registrationForm.controls.password.value;
    let firstName = this.registrationForm.controls.firstName.value;
    // let lastName = this.registrationForm.controls.lastName.value;
    // let phone = this.registrationForm.controls.phone.value;
    // let termsCheckbox = this.registrationForm.controls.termsCheckbox.value;
    // return [username, password, firstName, lastName, phone, termsCheckbox];
    return [username, password, firstName];
  }
}
