import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'property-overview',
  templateUrl: './property-overview.component.html',
  styleUrls: ['./property-overview.component.scss']
})
export class PropertyOverviewComponent implements OnInit, OnChanges {

  @Output() deleteProp = new EventEmitter();  

  @Input("property") property;
  @Input() postalCodeOnly = false;
  @Input() ownProperty = false;


  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
  }

  public deleteProperty(property){
    this.deleteProp.emit(property);
  }

}
