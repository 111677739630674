import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../common/config.service';
import { DefaultService } from '../common/default-service';

@Injectable({
  providedIn: 'root'
})
export class ShareableService extends DefaultService {

  constructor(private http: HttpClient) {
    super(new ConfigService().apiUrl);
  }

  public getShareableCode ( code: string ) {
    return this.http.get(this.api() + 'shareable/public/byCode/' + code );
  }

}
