import { Component, Input, OnInit } from '@angular/core';
import { CurrencyPipe, PercentPipe } from '@angular/common';

@Component({
  selector: 'calculator-display',
  templateUrl: './calculator-display.component.html',
  styleUrls: ['./calculator-display.component.scss']
})
export class CalculatorDisplayComponent implements OnInit {

  @Input('fields') fields: any;
  @Input('totalField') totalField: any;
  @Input('outputs') outputs: any;
  @Input('totalType') totalType: any;
  @Input('forceNegative') forceNegative: any;

  constructor(public currencyPipe: CurrencyPipe, public percentPipe: PercentPipe) {}

  ngOnInit() {
  }

  public convertFieldValue (field){
    return field.transformer != undefined && typeof field.transformer == 'function' ? this.convertType(field.transformer(field.control.value), field.currentUnitType, null) : this.convertType(field.control.value, field.currentUnitType, null);
  }

  public convertType(value, type, key) {
    let finalValue = null;
    if (type == 'currency') {
      finalValue = key ? this.currencyPipe.transform(value(key)) : this.currencyPipe.transform(value);
    }
    else if (type == 'percent') {
      finalValue = key ? this.percentPipe.transform(value(key) / 100, '1.2-2') : this.percentPipe.transform(value / 100, '1.2-2');
    }
    else {
      finalValue = value;
    }
    return finalValue;
  }

}
