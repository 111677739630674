import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AttachSession } from 'protractor/built/driverProviders';
import { ShareableService } from './shareable.service';

@Component({
  selector: 'shareable',
  templateUrl: './shareable.component.html',
  styleUrls: ['./shareable.component.scss']
})
export class ShareableComponent implements OnInit {

  public data: string;
  public shareableType = '';
  public code = '';
  public sharedObject: any = null;
  @Input() public state: any;


  constructor(public shareableService: ShareableService, public route: ActivatedRoute, public location: Location) {
    this.shareableType = this.route.snapshot.children[0].routeConfig.children[0].path;

    //TODO - couldn't figure out to retrieve code via route params, so just using this for now.
    let locationPath = this.location.path().split('/');
    this.code = locationPath[locationPath.length - 1];

    this.shareableService.getShareableCode(this.code).subscribe((sharedObject) => {
      localStorage.setItem("shareable-auth-token", this.code);
      this.sharedObject = sharedObject;
    });
  }

  ngOnInit() {
  }

}
