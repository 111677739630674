import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl } from '@angular/forms';


@Component({
  selector: 'field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss']
})
export class FieldComponent implements OnInit {

  @Input("fieldDefinition") fieldDefinition;
  @Input("value") value;


  constructor() { }

  ngOnInit() {
    //Initializing from the control
    this.fieldDefinition.currentUnitType = this.fieldDefinition.currentUnitType ? this.fieldDefinition.currentUnitType :  'currency';
    //If type is 'display' then the value is just the value property
    if (this.fieldDefinition.type == 'display') {
      this.value = this.fieldDefinition.value;
    }

    if (this.fieldDefinition?.control?.value != null && this.fieldDefinition?.control?.value.length > 0  ) {
        this.value = this.fieldDefinition.control.value;
    }


    //If value changes from the control
    this.fieldDefinition?.control?.valueChanges.subscribe((value) => {
      this.value = value;
    });

    //Initializing for drop down values if nothing is passed in
    if (this.fieldDefinition.type == 'dropdown' && this.value == null){
      this.valueChanged(this.fieldDefinition.options[0]);
    }
  }

  //Changing the value of the control if the value is changed from the field input
  public valueChanged($event){
    this.value = $event;
    this.fieldDefinition.control.setValue(this.value);
    if (this.fieldDefinition.onChange != null){
      this.fieldDefinition.onChange($event);
    }
  }

  public switchUnitType (unitType) {
    if (unitType == this.fieldDefinition.currentUnitType) {
      return;
    }

    let converter = this.fieldDefinition.converter(unitType);
    this.fieldDefinition.currentUnitType = unitType;
    this.valueChanged(converter);
  }




}
