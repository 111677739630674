import { Component, OnInit } from '@angular/core';
import { StatisticsService } from '../statistics/statistics.service';
import { AuthService } from '../login/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public totalProperties;
  public myProperties;
  public myTotalRevenue;
  public name = '';

  constructor(public statisticsService : StatisticsService, public authService: AuthService) { }


  ngOnInit() {

    this.name = this.authService.getAuthState().landlord.firstName;
    window['mixpanel'].track('Jumped to: Dashboard');

    this.statisticsService.getTotalNumberOfProperties().subscribe((statistics: any) => {
      this.totalProperties = statistics.totalNumberOfProperties;
    });

    this.statisticsService.getMyStatistics().subscribe((statistics: any) => {
      this.myProperties = statistics.properties;
      this.myTotalRevenue = statistics.revenue;
    });
  }

}
