import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalysisService } from '../analysis.service';

@Component({
  selector: 'app-analysis-detail',
  templateUrl: './analysis-detail.component.html',
  styleUrls: ['./analysis-detail.component.scss']
})
export class AnalysisDetailComponent implements OnInit {

  public analysis : any;

  constructor(public analysisService: AnalysisService, private route: ActivatedRoute) {
    this.route.params.subscribe((params) => {
      this.analysisService.getMyAnalysisById(params['id']).subscribe((analysis:any) => {
        window['mixpanel'].track('Jumped to: Single Analysis');
        analysis.analysis = JSON.parse(analysis.analysis);
        this.analysis = analysis;
      });
    });
  }

  ngOnInit() {
  }

}
