import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/common/config.service';
import { DefaultService } from 'src/app/common/default-service';

@Injectable({
  providedIn: 'root'
})
export class AnalysisService extends DefaultService {

  constructor(private http: HttpClient) {
    super(new ConfigService().apiUrl);
  }

  public createAnalysis(name, analysis) {
    return this.http.post(this.api() + 'analysis/create/' + name, analysis);
  }


  public deleteAnalysis(name) {
    return this.http.delete(this.api() + 'analysis/' + name);
  }

  public deleteAnalysisByIds(ids) {
    return this.http.delete(this.api() + 'analysis/deleteByIds?analysisIds='+ids );
  }

  public getMyAnalysis() {
    return this.http.get(this.api() + 'analysis/user');
  }

  public getMyAnalysisById(id) {
    return this.http.get(this.api() + 'analysis/' + id);
  }

  public saveAnalysis(id, analysis) {
    let analysisToSend = Object.assign({}, analysis);
    analysisToSend.analysis = analysisToSend.analysis == null ? {} : analysisToSend.analysis;
    analysisToSend.analysis = JSON.stringify(analysisToSend.analysis);
    return this.http.post(this.api() + 'analysis/save/' + id, analysisToSend);
  }

  public createShareableLink(id) {
    return this.http.post(this.api() + 'analysis/createShareableLink/' + id, null);
  }

}
