import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { property } from "lodash";
import { ConfigService } from "../common/config.service";
import { DefaultService } from "../common/default-service";

@Injectable({
  providedIn: "root",
})
export class CalculatorService extends DefaultService {
  constructor(private http: HttpClient) {
    super(new ConfigService().apiUrl);
  }

  public mortgagePayment(mortgageAmount, mortgageRate, amortizationPeriodInMonths, propertyPrice, downPayment) {
    let params = new HttpParams().set('mortgageAmount', mortgageAmount).set('mortgageRate', mortgageRate).set('amortizationPeriodInMonths', amortizationPeriodInMonths).set('propertyPrice', propertyPrice).set('downPayment', downPayment);
    return this.http.get(this.api() + 'report/mortgagePayment', {params});
  }

  public generateReport(analysisValues) {
    return this.http.post(this.api() + 'report/', analysisValues);
  }
}
