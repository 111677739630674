import { Component, OnInit } from "@angular/core";

@Component({
  selector: "expenses-chart",
  templateUrl: "./expenses-chart.component.html",
  styleUrls: ["./expenses-chart.component.scss"],
})
export class ExpensesChartComponent implements OnInit {

  public data :any = {
      legendData : ['Vacancy Rate', 'Maintenance', 'Property Management', 'Property Insurance'],
      seriesData: [
        {name: 'Vacancy Rate', value:100},
        {name: 'Maintenance', value:100},
        {name: 'Property Management', value:200},
        {name: 'Property Insurance', value:50},
    ]
  };


  public option = {
    title: {
        text: 'Monthly Expenses',
        // subtext: '纯属虚构',
        left: 'center'
    },
    tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)'
    },
    legend: {
        type: 'scroll',
        orient: 'vertical',
        right: 10,
        top: 20,
        bottom: 20,
        data: this.data.legendData,

        selected: this.data.selected
    },
    series: [
        {
            name: 'Monthly Expenses',
            type: 'pie',
            radius: '55%',
            center: ['40%', '50%'],
            data: this.data.seriesData,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    ]
};

  constructor() {}

  ngOnInit() {
  }
}
