<div class="w-100">
  <div class="row menu">
    <div class="col ">
      <search-bar [searchCriteria]="searchCriteria" (search)="searchThrottle()"></search-bar>
    </div>
  </div>

  <div class="results" *ngIf="!toggle.loading">
    <div class="row">
      <div class ="col" *ngIf="!searchResults.sampleSize">
        <h2 *ngIf="!initialSearch" class="text-muted text-center">Search for an address to check rents.</h2>
        <h2 *ngIf="initialSearch" class="text-muted text-center">No results found.</h2>
        <p class="help-text text-muted text-center">
          <span *ngIf="!initialSearch" class="text-muted text-center">By inserting a starting a location you can see rents nearby.</span>
          <span *ngIf="initialSearch" class="text-muted text-center">Try changing the radius, bedrooms, and washrooms</span>
        </p>
      </div>
    </div>

    <div class="mt-4" *ngIf="searchResults.sampleSize > 0">
      <div class="row w-100 d-flex justify-content-center">
        <div class="col error boxed-container primary-boxed-container-color text-center text-success">
            <span class="priceValue">${{ (searchResults.mean - searchResults.stdDeviation) | number : '1.0-0' }}</span>
          <div class="help-text text-muted">Lower Price Range</div>
        </div>

        <div class="col mean boxed-container primary-boxed-container-color text-center text-success">
          <span class="priceValue">${{ searchResults.mean | number : '1.0-0' }}</span>
          <div class="help-text text-muted">Average Rent</div>
        </div>

        <div class="col error boxed-container primary-boxed-container-color text-center text-success">
          <span class="priceValue">${{ (searchResults.mean + searchResults.stdDeviation) | number : '1.0-0' }}</span>
          <div class="help-text text-muted">Higher Price Range</div>
        </div>

        <div class="col totalSize primary-boxed-container-color boxed-container text-center">
          <span class="priceValue">{{ searchResults.sampleSize | number : '1.0-0' }}</span>
          <div class="help-text text-muted">Total Properties in Search</div>
        </div>
      </div>

      <div class="container mt-4">
        <div class="text-muted text-left">
          Sample Properties
        </div>
        <property-overview *ngFor="let property of searchResults.sampleProperties" [property]="property" [postalCodeOnly]="true"></property-overview>
     </div>
    </div>
  </div>

  <div class="loading" *ngIf="toggle.loading">
    <mat-spinner></mat-spinner>
  </div>
</div>

