import { AfterViewInit, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';
import { AuthService } from 'src/app/login/auth.service';

@Component({
  selector: 'add-property',
  templateUrl: './add-property.component.html',
  styleUrls: ['./add-property.component.scss']
})
export class AddPropertyComponent implements OnInit, AfterViewInit{

  public property = {
    address: null,
    address_line_two: null,
    city: null,
    rent: null,
    washrooms: null,
    bedrooms: null,
    startDate: null,
    endDate: null,
    postal_code: null,
    houseType: null,
    landlordId: null
  };

  public newPropertyForm: FormGroup;
  public fullAddress = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<AddPropertyComponent>, public formBuilder: FormBuilder, public authService: AuthService, public elementRef: ElementRef) { }
  
  ngAfterViewInit(): void {
    setTimeout(() => {
      let searchField = document.querySelector('#searchField .mat-form-field-wrapper');
      let autoCompleteBox = document.querySelector('.pac-container');
      searchField.append(autoCompleteBox);
    }, 300);
  }

  ngOnInit() {
    this.property = {
      address: null,
      address_line_two: null,
      city: null,
      rent: null,
      washrooms: null,
      bedrooms: null,
      startDate: null,
      endDate: null,
      postal_code: null,
      houseType: null,
      landlordId: null
    };

    this.newPropertyForm = this.formBuilder.group(
      {
        address: [,{validators:[Validators.required], updateOn: 'change'}],
        addressLineTwo: [,{validators:[], updateOn: 'change'}],
        // city: [,{validators:[Validators.required, Validators.pattern("^[A-Za-z]*$")], updateOn: 'change'}],
        rent: [,{validators:[Validators.required, Validators.pattern("^[0-9]*$")], updateOn: 'change'}],
        bedrooms: [,{validators:[Validators.required], updateOn: 'change'}],
        bathrooms: [,{validators:[Validators.required], updateOn: 'change'}],
        houseType: [,{validators:[Validators.required], updateOn: 'change'}],
        startDate: [,{validators:[Validators.required], updateOn: 'change'}],
        // endDate: [,{validators:[Validators.required], updateOn: 'change'}],
      }
    );
    
  }


  public handleAddressChange($event) {
    let streetNumber = _.find($event.address_components, { types: ['street_number'] });
    let street = _.find($event.address_components, { types: ['route'] });
    let city = _.find($event.address_components, { types: ['locality'] });
    let postalCode = _.find($event.address_components, { types: ['postal_code'] });
    
    this.property.address = streetNumber.long_name + " " + street.long_name;
    this.property.city = city.long_name;
    this.property.postal_code = postalCode.long_name;

    //Should probably bring in these later on
    let province = _.find($event.address_components, { types: ['administrative_area_level_1'] });
    let country = _.find($event.address_components, { types: ['country'] });
  }

  public closeDialog(){
    this.dialogRef.close(this.property || null);
  }

}
