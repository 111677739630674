<div class="w-100 home-body">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col">
        <h6>Hello {{name}}, Welcome Back To</h6>
        <h1>The Spotr Network</h1>
        
      </div>
    </div>

    <div class="row justify-content-md-center">
      <div class="col boxed-container primary-boxed-container-color">

        <h3>Dashboard</h3>

        <p>You will see 4 tabs to your left.</p> 

        <p>You are currently at the dashboard tab.</p>

        <p>The "My Properties" tab gives you the ability to save the properties that you currently own. By saving your properties here, the rents that are connected to your properties will be anonymously used in the Rent Search tool.</p>

        <p>The "Rent Search" tool, our premium feature, is used to determine the average rent in the area your potential investment property is located in based on rent data that is captured through our proprietary algorithm.</p>

        <p>The "Analyzer" tool gives you access to a dynamic calculator to analyze the numbers on your potential investment property.</p>

      </div>
      <div class="col">
        <div class="row">
          <div class="col boxed-container secondary-boxed-container-color">
            <div class="number">${{ myTotalRevenue | number  }}</div>
            Total revenue per month
          </div>
        </div>
        <div class="row">
          <div class="col boxed-container secondary-boxed-container-color">
            <div class="number">{{ myProperties }}</div>
            Your properties
          </div>
          <div class="col boxed-container secondary-boxed-container-color">
            <div class="number">{{ totalProperties }}</div>
            Total properties in the network
          </div>
        </div>
      </div>
    </div>
  </div>