import { SelectionModel } from "@angular/cdk/collections";
import { AfterViewInit } from "@angular/core";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSort, MatTable, MatTableDataSource } from "@angular/material";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { create } from "lodash";
import { isThisTypeNode } from "typescript";
import { AnalysisNameModalComponent } from "../analysis-name-modal/analysis-name-modal.component";
import { AnalysisService } from "../analysis.service";

@Component({
  selector: "analysis-list",
  templateUrl: "./analysis-list.component.html",
  styleUrls: ["./analysis-list.component.scss"],
})
export class AnalysisListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  public dataSource = new MatTableDataSource();
  public columnsToDisplay = ["analysisName", "analysis.propertyDetails.address", "analysis.propertyDetails.propertyPrice"];

  public initialSelection = [];
  public allowMultiSelect = true;
  public selection = new SelectionModel<any>(
    this.allowMultiSelect,
    this.initialSelection
  );

  constructor(
    public analysisService: AnalysisService,
    public dialog: MatDialog,
    public router: Router
  ) {
    window["mixpanel"].track("Jumped to: Analysis List");
    this.getAllAnalysis();
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  public addAnalysis() {
    this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AnalysisNameModalComponent, {
      width: "400px",
      height: "200px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == null || result.length == 0) {
        return;
      }

      this.analysisService
        .createAnalysis(result, {})
        .subscribe((createdAnalysis: any) => {
          window["mixpanel"].track("Adding a new analysis", {
            "Analysis Name": createdAnalysis.analysisName,
          });
          if (createdAnalysis != null) {
            createdAnalysis.analysis = JSON.parse(createdAnalysis.analysis);
            // this.dataSource.data.push(createdAnalysis);
            // this.dataSource.data.push(createdAnalysis);
            this.dataSource = new MatTableDataSource(Object.assign([createdAnalysis], this.dataSource.data));
            this.dataSource.sort = this.sort;
            this.dataSource._updateChangeSubscription();
            this.router.navigate([
              "/analysis",
              createdAnalysis.id,
              "initialize",
            ]);
          }
        });
    });
  }

  public getAllAnalysis() {
    this.analysisService.getMyAnalysis().subscribe((analysis: []) => {
      this.dataSource = new MatTableDataSource(
        this.convertAllAnalysisToObjects(analysis)
      );
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (obj, property) => _.get(obj, property);
      this.dataSource._updateChangeSubscription();
    });
  }

  public convertAllAnalysisToObjects(allAnalysis) {
    for (let analysis of allAnalysis) {
      analysis.analysis = JSON.parse(analysis.analysis);
    }
    return allAnalysis;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  public deleteSelected() {
    let toDeleteIds = _.map(this.selection.selected, (a) => a.id);
    if (toDeleteIds.length > 0) {
      this.analysisService
        .deleteAnalysisByIds(toDeleteIds.join(","))
        .subscribe(() => {
          _.remove(this.dataSource.data, (a: any) => {
            return toDeleteIds.includes(a.id);
          });
          this.dataSource._updateChangeSubscription();
          this.selection.clear();
        });
    }
  }

  public deleteAnalysis(analysis) {
    this.analysisService
      .deleteAnalysis(analysis.analysisName)
      .subscribe((result) => {
        this.getAllAnalysis();
      });
  }
}
