import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'registration-confirmation',
  templateUrl: './registration-confirmation.component.html',
  styleUrls: ['./registration-confirmation.component.scss']
})
export class RegistrationConfirmationComponent implements OnInit {

  public token: string;

  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe (params => {
      this.token = params['token'];
    });


   }

  ngOnInit() {

  }

}
