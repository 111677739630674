import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { AppRoutes } from "./app.routing";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { MenuComponent } from "./menu/menu.component";
import { RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { MyPropertiesComponent } from "./my-properties/my-properties.component";
import { SearchComponent } from "./search/search.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { PropertyOverviewComponent } from "./properties/property-overview/property-overview.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  GestureConfig,
  MatDatepickerModule,
  MatDialogModule,
  MatInputModule,
  MatNativeDateModule,
  MatOptionModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatButtonModule,
  MatSortModule,
} from "@angular/material";
import { AddPropertyComponent } from "./modals/add-property/add-property.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SearchBarComponent } from "./search/search-bar/search-bar.component";
import { LoginComponent } from "./login/login.component";
import { TokenInterceptor } from "./interceptors/token.interceptor";
import { RegisterComponent } from "./register/register.component";
import { ResponseInterceptor } from "./interceptors/response.interceptor";
import { SimpleNotificationsModule } from "angular2-notifications";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { RecoveryComponent } from "./forgot-password/recovery/recovery.component";
import { MatSliderModule } from "@angular/material/slider";
import { HAMMER_GESTURE_CONFIG, HammerModule } from "@angular/platform-browser";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { RegistrationConfirmationComponent } from "./forgot-password/registration-confirmation/registration-confirmation.component";
import { CalculatorComponent } from "./calculator/calculator.component";
import { CalculatorSectionComponent } from "./calculator/calculator-section/calculator-section.component";
import { FieldComponent } from "./shared/field/field.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { CalculatorDisplayComponent } from "./calculator/calculator-display/calculator-display.component";
import { CurrencyPipe, PercentPipe } from "@angular/common";
import { AnalysisDetailComponent } from "./calculator/analysis/analysis-detail/analysis-detail.component";
import { AnalysisListComponent } from "./calculator/analysis/analysis-list/analysis-list.component";
import { AnalysisNameModalComponent } from "./calculator/analysis/analysis-name-modal/analysis-name-modal.component";
import { MatTableModule } from "@angular/material/table";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { NgxEchartsModule } from "ngx-echarts";
import { ExpensesChartComponent } from './calculator/analysis/charts/expenses-chart/expenses-chart.component';
import { ShareableComponent } from './shareable/shareable.component';
import { SharedLinkDialogComponent } from './shared/shared-link-dialog/shared-link-dialog.component';
import { AnalysisSharedComponent } from "./shareable/analysis-shared/analysis-shared.component";
import { ClipboardModule } from 'ngx-clipboard';



@NgModule({
  declarations: [
    AppComponent,
    AnalysisDetailComponent,
    AnalysisListComponent,
    AnalysisNameModalComponent,
    AnalysisSharedComponent,
    AddPropertyComponent,
    ExpensesChartComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    MenuComponent,
    MyPropertiesComponent,
    PropertyOverviewComponent,
    SearchComponent,
    SearchBarComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    RecoveryComponent,
    RegistrationConfirmationComponent,
    CalculatorComponent,
    CalculatorSectionComponent,
    CalculatorDisplayComponent,
    FieldComponent,
    ShareableComponent,
    SharedLinkDialogComponent
   ],
  imports: [
    AppRoutes,
    BrowserModule,
    BrowserAnimationsModule,
    ClipboardModule,
    FormsModule,
    HttpClientModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatCheckboxModule,
    MatIconModule,
    SimpleNotificationsModule.forRoot(),
    MatSliderModule,
    MatSortModule,
    MatSlideToggleModule,
    MatExpansionModule,
    HammerModule,
    GooglePlaceModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    NgxEchartsModule.forRoot({
      echarts: () => import("echarts"),
    }),
  ],
  exports: [RouterModule],
  entryComponents: [AddPropertyComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    CurrencyPipe,
    PercentPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
