import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../common/app.service';
import { AuthService } from './auth.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @Input() public state:any;
  @Output() public stateChange = new EventEmitter();

  public loginForm: FormGroup;
  public formControls: any;
  public submitted: boolean = false;

  public loading = false;
  public username = null;
  public password = null;

  constructor(public loginService: AuthService, public formBuilder: FormBuilder, public appService: AppService) { }


  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.formControls = this.loginForm.controls;
  }

  public onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    let username = this.loginForm.controls.username.value;
    let password = this.loginForm.controls.password.value;
    if (username && password) {
      this.appService.loadingService.next(true);
      this.loginService.login(username, password).subscribe((token: any) => {
        localStorage.setItem('token', token.token);
        this.loginService.getAuth().subscribe((auth:any) => {
          this.loginService.setLoginState(auth);
          window['mixpanel'].track('Logged In');
          this.loginService.loginSubscription.next(true);
          this.appService.loadingService.next(false);
        });
      }, (err) => {
        this.loginService.loginSubscription.next(false);
        this.appService.loadingService.next(false);
      });
    }
  }

}
